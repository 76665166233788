<template>
  <div class="flex h-screen">
    <!-- Sidebar -->
    <aside class="w-11rem surface-100 text-white flex flex-column justify-content-between h-screen overflow-y-auto">
      <div class="mt-4 m-3">
        <div class="text-center">
          <img :src="logoImage" alt="Logo" class="w-5rem h-auto mb-2">
          <div>
            <p class="text-sm letter-spacing-2 text-gray-400 m-0">Welcome <span class="capitalize">{{ userName }}</span></p>
          </div>
        </div>
        <ul class="list-none p-0 m-0 mt-2 border-top-1 border-gray-700 pt-2">
          <li class="mb-1">
            <router-link to="/" class="flex align-items-center p-3 text-gray-300 hover:bg-gray-800 border-round-sm no-underline transition-colors transition-duration-150" exact-active-class="bg-gray-800 text-white">
              <i class="pi pi-home mr-2"></i> Dashboard
            </router-link>
          </li>
          <li class="mb-1">
            <router-link to="/attendance" class="flex align-items-center p-3 text-gray-300 hover:bg-gray-800 border-round-sm no-underline transition-colors transition-duration-150" exact-active-class="bg-gray-800 text-white">
              <i class="pi pi-key mr-2"></i> Attendance
            </router-link>
          </li>
          <li class="mb-1">
            <router-link to="/roster" class="flex align-items-center p-3 text-gray-300 hover:bg-gray-800 border-round-sm no-underline transition-colors transition-duration-150" exact-active-class="bg-gray-800 text-white">
              <i class="pi pi-calendar-plus mr-2"></i> Roster
            </router-link>
          </li>
          <li class="mb-1">
            <router-link to="/profiles" class="flex align-items-center p-3 text-gray-300 hover:bg-gray-800 border-round-sm no-underline transition-colors transition-duration-150" exact-active-class="bg-gray-800 text-white">
              <i class="pi pi-sitemap mr-2"></i> Profiles
            </router-link>
          </li>
          <li class="mb-1 mt-2 pt-3 border-top-1 border-200">
            <router-link to="/logs" class="flex align-items-center p-3 text-gray-300 hover:bg-gray-800 border-round-sm no-underline transition-colors transition-duration-150" exact-active-class="bg-gray-800 text-white">
              <i class="pi pi-code mr-2"></i> Logs
            </router-link>
          </li>
          <li class="mb-1">
            <router-link to="/complaints" disabled="" class="pointer-events-none flex align-items-center p-3 text-gray-500 hover:bg-gray-800 border-round-sm no-underline transition-colors transition-duration-150" exact-active-class="bg-gray-800 text-white">
              <i class="pi pi-flag mr-2"></i> Complaints
            </router-link>
          </li>
          <li class="mb-1">
            <router-link to="/complaints" disabled="" class="pointer-events-none flex align-items-center p-3 text-gray-500 hover:bg-gray-800 border-round-sm no-underline transition-colors transition-duration-150" exact-active-class="bg-gray-800 text-white">
              <i class="pi pi-file-pdf mr-2"></i> Reports
            </router-link>
          </li>
        </ul>
      </div>
      <div class="p-3">
        <div class="text-center mb-2">
          <Button icon="pi pi-sync" class="p-button surface-200 hover:surface-300 px-3 py-2 mr-2" :disabled="isClearingData || loadingLogout" @click="performClearData"></Button>
          <Button icon="pi pi-power-off" class="p-button surface-200 hover:surface-300 px-3 py-2" :disabled="isClearingData || loadingLogout" @click="showLogoutDialog"></Button>
        </div>
        <div class="text-center line-height-1">
          <div class="opacity-80 mb-2">
            <div class="imab-logo mt-4">
              <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
            </div>
            <span class="text-sm text-400" style="letter-spacing: .4rem;">core</span>
          </div>
          <div class="text-xs border-round-md lowercase bg-gray-600 text-400 p-1 w-10 mx-auto">{{ envName }} {{ envVersion }}</div>
        </div>
      </div>
    </aside>

    <!-- Main Content Area -->
    <main class="flex-1 surface-0 overflow-y-auto h-screen">
      <div class="p-3">
        <PrimeToast />
        <PrimeToast position="bottom-right" group="br" />
        <router-view></router-view>
      </div>
    </main>
  </div>

  <!-- Logout Dialog -->
  <Dialog v-model:visible="showLogoutForm" :modal="true" :closable="false">
    <h3 class="m-0 p-0">Do you really want to Logout?</h3>
    <template #footer>
      <Button label="No" class="p-button-secondary mr-2" @click="showLogoutForm = false" :disabled="isClearingData || loadingLogout" />
      <Button label="Yes Logout" class="p-button-primary" @click="performLogoutConfirmation" :loading="loadingLogout" :disabled="loadingLogout" />
    </template>
  </Dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useUser } from '@/composables/useUser';
import { useClearData } from '@/composables/useClearData';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import logo from '@/assets/orion-logo-white-bg.png';

const router = useRouter();
const authStore = useAuthStore();
const { logout } = authStore;

const { userName, userId } = useUser();
const { isClearingData, clearDataAndRefresh } = useClearData();

const logoImage = logo;
const showLogoutForm = ref(false);
const loadingLogout = ref(false);
const envName = process.env.VUE_APP_ENV_NAME;
const envVersion = process.env.VUE_APP_ENV_VERSION;

const showLogoutDialog = () => {
  showLogoutForm.value = true;
};

const performLogoutConfirmation = async () => {
  loadingLogout.value = true;
  await logout({ userId: userId.value, currentTimeUnix: 0 });
  await clearDataAndRefresh();
  router.push('/login');
  loadingLogout.value = false;
  showLogoutForm.value = false;
};

const performClearData = async () => {
  isClearingData.value = true;
  await clearDataAndRefresh();
  router.push('/login');
  isClearingData.value = false;
};

</script>

<style>
body{
  padding: 0;
  margin: 0;
}
</style>