// composables/useAttendance.js
import { ref, watch } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

const ATTENDANCE_CACHE_KEY = 'attendanceData';

export function useAttendance() {
  const saveToLocalStorage = (data) => {
    localStorage.setItem(ATTENDANCE_CACHE_KEY, JSON.stringify(data));
  };

  const getFromLocalStorage = () => {
    const cachedData = localStorage.getItem(ATTENDANCE_CACHE_KEY);
    return cachedData ? JSON.parse(cachedData) : [];
  };

  // Initialize allAttendance with data from localStorage
  const allAttendance = ref(getFromLocalStorage());
  const attendanceLoading = ref(false);
  const attendanceError = ref(null);
  const attendanceWarning = ref(null);

  const fetchAllAttendance = async (employeeId = 0, companyId = 0, targetDate = 'None') => {
    attendanceLoading.value = true;
    attendanceError.value = null;
    attendanceWarning.value = null;

    try {
      // First, set allAttendance to the data from localStorage
      allAttendance.value = getFromLocalStorage();

      // Then, fetch fresh data from API
      const url = API_ENDPOINTS.fetchAttendance
        .replace('{employeeId}', employeeId)
        .replace('{companyId}', companyId)
        .replace('{targetDate}', targetDate);

      const response = await axiosClient.get(url);
      
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      let modifiedResponse = response.data;

      // Check if the response is an array and not empty
      if (Array.isArray(modifiedResponse) && modifiedResponse.length > 0) {
        if (employeeId === 0 && companyId === 0) {
          modifiedResponse = modifiedResponse.map(entry => ({
            ...entry,
            shift_in2: entry.shift_in.substring(0, 16)
          }));
        }
      } else {
        // If the response is empty or not an array, set it to an empty array
        modifiedResponse = [];
        // Set warning message
        attendanceWarning.value = response.data.message || 'No attendance records found';
      }

      // Update localStorage and reactive ref with new data
      saveToLocalStorage(modifiedResponse);
      allAttendance.value = modifiedResponse;
    } catch (err) {
      console.error('Error fetching attendance data:', err);
      attendanceError.value = err.message || 'Failed to fetch attendance data';
      // Clear localStorage and set allAttendance to an empty array on error
      saveToLocalStorage([]);
      allAttendance.value = [];
    } finally {
      attendanceLoading.value = false;
    }
  };

  // Watch for changes in allAttendance and update localStorage
  watch(allAttendance, (newValue) => {
    saveToLocalStorage(newValue);
  }, { deep: true });

  return {
    allAttendance,
    attendanceLoading,
    attendanceError,
    attendanceWarning,
    fetchAllAttendance
  };
}