// composables/api/useLogsSingle.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useLogsSingle() {
  const logs = ref([]);
  const logsLoading = ref(false);
  const logsError = ref(null);

  const handleError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      return err.response.data.message;
    }
    return 'An unexpected error occurred. Please try again later.';
  };

  const fetchLogsSingle = async (tableName, recordId, type) => {
    logsLoading.value = true;
    logsError.value = null;

    try {
      const url = API_ENDPOINTS.fetchLogsById
        .replace('{tableName}', tableName)
        .replace('{recordId}', recordId)
        .replace('{type}', type);

      const response = await axiosClient.get(url);
      
      if (Array.isArray(response.data)) {
        logs.value = response.data;
      } else {
        console.warn('Received non-array data from API:', response.data);
        logs.value = [];
      }
    } catch (err) {
      logsError.value = handleError(err);
      logs.value = [];
    } finally {
      logsLoading.value = false;
    }
  };

  return {
    logs,
    logsLoading,
    logsError,
    fetchLogsSingle
  };
}