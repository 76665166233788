// composables/api/useRosterBulkUpdate.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import axios from 'axios';

export function useRosterBulkUpdate() {
  const isSubmittingRoster = ref(false);
  const isSubmittingLeave = ref(false);

  const updateRosters = async (formattedRosters, action = '') => {
    // Set the appropriate loading state
    if (action === 'LEAVE') {
      if (isSubmittingLeave.value) return { success: false, message: 'Leave submission already in progress' };
      isSubmittingLeave.value = true;
    } else {
      if (isSubmittingRoster.value) return { success: false, message: 'Roster update already in progress' };
      isSubmittingRoster.value = true;
    }

    const payload = { rows: formattedRosters };

    try {
      const response = await axiosClient.post(API_ENDPOINTS.bulkEditRoster, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 10000, // 10 seconds timeout
      });

      return {
        success: true,
        message: response.data.message || 'Roster updated successfully'
      };
    } catch (error) {
      console.error('Error updating rosters:', error);
      let errorMessage = 'Failed to update rosters';
      let severity = 'error';

      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          errorMessage = error.response.data.error || `Server error: ${error.response.status}`;
          
          if (error.response.status === 400) {
            severity = 'warn';
          } else if (error.response.status === 409) {
            severity = 'info';
          }
        } else if (error.request) {
          console.error('Error request:', error.request);
          errorMessage = 'No response from server';
        } else {
          console.error('Error message:', error.message);
          errorMessage = error.message;
        }
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      return {
        success: false,
        message: errorMessage,
        severity: severity
      };
    } finally {
      // Reset the appropriate loading state
      action === 'LEAVE' ? isSubmittingLeave.value = false : isSubmittingRoster.value = false;
    }
  };

  return {
    isSubmittingRoster,
    isSubmittingLeave,
    updateRosters
  };
}