// composables/useRosterSingle.js
import { ref, readonly } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useRosterSingle() {
  const singleRoster = ref(null);
  const loading = ref(false);
  const error = ref(null);
  const warning = ref(null);

  const fetchSingleRoster = async (employeeId, shiftType) => {
    if (!employeeId || !shiftType) {
      error.value = "No Employee ID or Shift Type provided";
      return;
    }

    loading.value = true;
    error.value = null;
    warning.value = null;

    try {
      const url = API_ENDPOINTS.fetchRostersSingle.replace('{employeeId}', employeeId).replace('{shiftType}', shiftType);
      const response = await axiosClient.get(url);
      
      const statusCode = response.status;

      if (statusCode === 200) {
        if (response.data && typeof response.data === 'object') {
          singleRoster.value = response.data;
        } else {
          throw new Error("Invalid response format");
        }
      } else if ((statusCode >= 201 && statusCode <= 205) || (statusCode >= 300 && statusCode <= 305)) {
        warning.value = handleWarning(response);
      } else if (statusCode >= 400) {
        throw new Error(response.data.message || "An error occurred");
      }
    } catch (err) {
      console.error("Error fetching single roster:", err);
      error.value = handleError(err);
    } finally {
      loading.value = false;
    }
  };

  const handleWarning = (response) => {
    if (response.data && response.data.message) {
      return response.data.message;
    }
    return 'The request was processed, but there might be some issues. Please check the data carefully.';
  };

  const handleError = (err) => {
    if (err.response) {
      const statusCode = err.response.status;
      if (statusCode >= 400 && statusCode <= 405) {
        if (err.response.data && err.response.data.message) {
          return err.response.data.message;
        }
        return 'An error occurred while processing your request. Please try again.';
      }
    }
    return 'An unexpected error occurred. Please try again later.';
  };

  return {
    singleRoster: readonly(singleRoster),
    loading: readonly(loading),
    error: readonly(error),
    warning: readonly(warning),
    fetchSingleRoster
  };
}