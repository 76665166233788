// composables/useExportScheduleImage.js
export function useExportScheduleImage() {
    const exportTableAsImage = async (tableData, weekDays, fileName, companyName) => {
      const svgNS = "http://www.w3.org/2000/svg";
      const svg = document.createElementNS(svgNS, "svg");
      const employeeNameWidth = 250;
      const weekColumnWidth = 120;
      const dayColumnWidth = 110;
      const cellHeight = 50;
      const headerHeight = 20;
      const padding = 30;
      const tableWidth = employeeNameWidth + weekColumnWidth + (weekDays.length * dayColumnWidth);
  
      const totalRows = tableData.length;
      const tableHeight = (totalRows + 1) * cellHeight + headerHeight;
  
      // Calculate the date range
      const firstWeekRange = tableData[0].weekRange.split(' to ');
      const lastWeekRange = tableData[tableData.length - 1].weekRange.split(' to ');
      const startDate = firstWeekRange[0];
      const endDate = lastWeekRange[1] || lastWeekRange[0]; // Use the end of the last week, or the start if there's no end
      const dateRange = `${startDate} to ${endDate}`;
  
      // Increase SVG height to accommodate the new date range line and footer
      svg.setAttribute("width", tableWidth + padding * 2);
      svg.setAttribute("height", tableHeight + padding * 2 + 40); // Added 40px for the new line and footer
      svg.setAttribute("xmlns", svgNS);
  
      // Create a white background
      const rect = document.createElementNS(svgNS, "rect");
      rect.setAttribute("width", "100%");
      rect.setAttribute("height", "100%");
      rect.setAttribute("fill", "white");
      svg.appendChild(rect);
  
      // Add title
      const title = document.createElementNS(svgNS, "text");
      title.setAttribute("x", (tableWidth + padding * 2) / 2);
      title.setAttribute("y", padding);
      title.setAttribute("text-anchor", "middle");
      title.setAttribute("font-size", "24");
      title.setAttribute("font-family", "Arial, sans-serif");
      title.setAttribute("font-weight", "bold");
      title.textContent = `${companyName} Schedule`;
      svg.appendChild(title);
  
      // Add date range
      const dateRangeText = document.createElementNS(svgNS, "text");
      dateRangeText.setAttribute("x", (tableWidth + padding * 2) / 2);
      dateRangeText.setAttribute("y", padding + 20); // Position it below the title
      dateRangeText.setAttribute("text-anchor", "middle");
      dateRangeText.setAttribute("font-size", "16");
      dateRangeText.setAttribute("font-family", "Arial, sans-serif");
      dateRangeText.textContent = dateRange;
      svg.appendChild(dateRangeText);
  
      // Create a group for the table and translate it to add padding
      const tableGroup = document.createElementNS(svgNS, "g");
      tableGroup.setAttribute("transform", `translate(${padding}, ${padding + 30})`); // Adjusted for the new line
      svg.appendChild(tableGroup);
  
      // Add table border
      const tableBorder = document.createElementNS(svgNS, "rect");
      tableBorder.setAttribute("x", 0);
      tableBorder.setAttribute("y", headerHeight);
      tableBorder.setAttribute("width", tableWidth);
      tableBorder.setAttribute("height", tableHeight - headerHeight);
      tableBorder.setAttribute("fill", "none");
      tableBorder.setAttribute("stroke", "#CCC");
      tableBorder.setAttribute("stroke-width", "2");
      tableGroup.appendChild(tableBorder);
  
      // Add header background
      const headerBg = document.createElementNS(svgNS, "rect");
      headerBg.setAttribute("x", 0);
      headerBg.setAttribute("y", headerHeight);
      headerBg.setAttribute("width", tableWidth);
      headerBg.setAttribute("height", cellHeight);
      headerBg.setAttribute("fill", "#444444");
      tableGroup.appendChild(headerBg);
  
      // Add header
      const headers = ["Agent Name", "Week", ...weekDays];
      let currentX = 0;
      headers.forEach((header, index) => {
        const text = document.createElementNS(svgNS, "text");
        if (index === 0) {
          text.setAttribute("x", currentX + employeeNameWidth / 2);
          currentX += employeeNameWidth;
        } else if (index === 1) {
          text.setAttribute("x", currentX + weekColumnWidth / 2);
          currentX += weekColumnWidth;
        } else {
          text.setAttribute("x", currentX + dayColumnWidth / 2);
          currentX += dayColumnWidth;
        }
        text.setAttribute("y", headerHeight + cellHeight / 2);
        text.setAttribute("text-anchor", "middle");
        text.setAttribute("dominant-baseline", "middle");
        text.setAttribute("font-family", "Arial, sans-serif");
        text.setAttribute("font-weight", "bold");
        text.setAttribute("font-size", "14");
        text.setAttribute("fill", "#FFF");
        text.textContent = header;
        tableGroup.appendChild(text);
      });
  
      // Add vertical lines (except for the week column)
      currentX = 0;
      [employeeNameWidth, weekColumnWidth, ...Array(weekDays.length).fill(dayColumnWidth)].forEach((width, index) => {
        if (index !== 1) { // Skip the line between Employee Name and Week
          const line = document.createElementNS(svgNS, "line");
          line.setAttribute("x1", currentX);
          line.setAttribute("y1", headerHeight);
          line.setAttribute("x2", currentX);
          line.setAttribute("y2", tableHeight);
          line.setAttribute("stroke", "#CCC");
          line.setAttribute("stroke-width", "1");
          tableGroup.appendChild(line);
        }
        currentX += width;
      });
  
      let currentY = headerHeight + cellHeight;
      let lastWeek = null;
      let weekStartY = currentY;
  
      // Add data rows
      tableData.forEach((row, rowIndex) => {
        const y = currentY + rowIndex * cellHeight;
  
        // Add horizontal line for each row (except for the last row)
        if (rowIndex < tableData.length - 1) {
          const rowLine = document.createElementNS(svgNS, "line");
          rowLine.setAttribute("x1", 0);
          rowLine.setAttribute("y1", y + cellHeight);
          rowLine.setAttribute("x2", employeeNameWidth);
          rowLine.setAttribute("y2", y + cellHeight);
          rowLine.setAttribute("stroke", "#CCC");
          rowLine.setAttribute("stroke-width", "1");
          tableGroup.appendChild(rowLine);
  
          const rowLine2 = document.createElementNS(svgNS, "line");
          rowLine2.setAttribute("x1", employeeNameWidth + weekColumnWidth);
          rowLine2.setAttribute("y1", y + cellHeight);
          rowLine2.setAttribute("x2", tableWidth);
          rowLine2.setAttribute("y2", y + cellHeight);
          rowLine2.setAttribute("stroke", "#CCC");
          rowLine2.setAttribute("stroke-width", "1");
          tableGroup.appendChild(rowLine2);
        }
  
        // Employee Name
        const employeeText = document.createElementNS(svgNS, "text");
        employeeText.setAttribute("x", employeeNameWidth / 2);
        employeeText.setAttribute("y", y + cellHeight / 2);
        employeeText.setAttribute("text-anchor", "middle");
        employeeText.setAttribute("dominant-baseline", "middle");
        employeeText.setAttribute("font-family", "Arial, sans-serif");
        employeeText.setAttribute("font-size", "14");
        employeeText.textContent = row.employee_name;
        tableGroup.appendChild(employeeText);
  
        // Week
        if (row.week !== lastWeek) {
          if (lastWeek !== null) {
            // Draw the merged week cell
            const weekRect = document.createElementNS(svgNS, "rect");
            weekRect.setAttribute("x", employeeNameWidth);
            weekRect.setAttribute("y", weekStartY);
            weekRect.setAttribute("width", weekColumnWidth);
            weekRect.setAttribute("height", y - weekStartY);
            weekRect.setAttribute("fill", "none");
            weekRect.setAttribute("stroke", "#CCC");
            tableGroup.appendChild(weekRect);
          }
  
          const weekText = document.createElementNS(svgNS, "text");
          weekText.setAttribute("x", employeeNameWidth + weekColumnWidth / 2);
          weekText.setAttribute("y", y + cellHeight / 2 - 10);
          weekText.setAttribute("text-anchor", "middle");
          weekText.setAttribute("dominant-baseline", "middle");
          weekText.setAttribute("font-family", "Arial, sans-serif");
          weekText.setAttribute("font-size", "14");
          weekText.setAttribute("font-weight", "bold");
          weekText.textContent = `Week ${row.week}`;
          tableGroup.appendChild(weekText);
  
          const weekRangeText = document.createElementNS(svgNS, "text");
          weekRangeText.setAttribute("x", employeeNameWidth + weekColumnWidth / 2);
          weekRangeText.setAttribute("y", y + cellHeight / 2 + 10);
          weekRangeText.setAttribute("text-anchor", "middle");
          weekRangeText.setAttribute("dominant-baseline", "middle");
          weekRangeText.setAttribute("font-family", "Arial, sans-serif");
          weekRangeText.setAttribute("font-size", "12");
          weekRangeText.textContent = row.weekRange;
          tableGroup.appendChild(weekRangeText);
  
          lastWeek = row.week;
          weekStartY = y;
        }
  
        // Days
        weekDays.forEach((day, dayIndex) => {
          const x = employeeNameWidth + weekColumnWidth + (dayIndex * dayColumnWidth);
          const dayData = row[day];
  
          if (dayData && dayData.start && dayData.end) {
            const timeText = document.createElementNS(svgNS, "text");
            timeText.setAttribute("x", x + dayColumnWidth / 2);
            timeText.setAttribute("y", y + cellHeight / 2 - 10);
            timeText.setAttribute("text-anchor", "middle");
            timeText.setAttribute("dominant-baseline", "middle");
            timeText.setAttribute("font-family", "Arial, sans-serif");
            timeText.setAttribute("font-size", "14");
            timeText.setAttribute("font-weight", "bold");
            timeText.textContent = `${dayData.start} - ${dayData.end}`;
            tableGroup.appendChild(timeText);
  
            const shiftText = document.createElementNS(svgNS, "text");
            shiftText.setAttribute("x", x + dayColumnWidth / 2);
            shiftText.setAttribute("y", y + cellHeight / 2 + 10);
            shiftText.setAttribute("text-anchor", "middle");
            shiftText.setAttribute("dominant-baseline", "middle");
            shiftText.setAttribute("font-family", "Arial, sans-serif");
            shiftText.setAttribute("font-size", "12");
            shiftText.textContent = dayData.shift_type;
            
            if (dayData.shift_type === 'SHIFT') {
              shiftText.setAttribute("fill", "#BBB");
            } else if (dayData.shift_type === 'LEAVE') {
              shiftText.setAttribute("fill", "red");
            } else if (dayData.shift_type === 'COVER') {
              shiftText.setAttribute("fill", "blue");
            }
            
            tableGroup.appendChild(shiftText);
          } else {
            const offText = document.createElementNS(svgNS, "text");
            offText.setAttribute("x", x + dayColumnWidth / 2);
            offText.setAttribute("y", y + cellHeight / 2);
            offText.setAttribute("text-anchor", "middle");
            offText.setAttribute("dominant-baseline", "middle");
            offText.setAttribute("font-family", "Arial, sans-serif");
            offText.setAttribute("font-size", "14");
            offText.setAttribute("fill", "#BBB");
            offText.textContent = "DAY OFF";
            tableGroup.appendChild(offText);
          }
        });
      });
  
      // Draw the last merged week cell
      const lastWeekRect = document.createElementNS(svgNS, "rect");
      lastWeekRect.setAttribute("x", employeeNameWidth);
      lastWeekRect.setAttribute("y", weekStartY);
      lastWeekRect.setAttribute("width", weekColumnWidth);
      lastWeekRect.setAttribute("height", tableHeight - weekStartY);
      lastWeekRect.setAttribute("fill", "none");
      lastWeekRect.setAttribute("stroke", "#CCC");
      tableGroup.appendChild(lastWeekRect);
  
      // Add footer text
      const footerText = document.createElementNS(svgNS, "text");
      footerText.setAttribute("x", (tableWidth + padding * 2) / 2);
      footerText.setAttribute("y", tableHeight + padding * 2 + 30); // Position it below the table
      footerText.setAttribute("text-anchor", "middle");
      footerText.setAttribute("font-size", "10");
      footerText.setAttribute("font-family", "Arial, sans-serif");
      footerText.setAttribute("fill", "#CCC");
      footerText.textContent = "Generated with IMAB Core";
      svg.appendChild(footerText);
  
      // Convert SVG to image and download
      const svgData = new XMLSerializer().serializeToString(svg);
      const svgBlob = new Blob([svgData], {type: "image/svg+xml;charset=utf-8"});
      const svgUrl = URL.createObjectURL(svgBlob);
  
      const img = new Image();
      img.onload = () => {
        const scale = 2; // Increase this for higher resolution
        const canvas = document.createElement("canvas");
        canvas.width = (tableWidth + padding * 2) * scale;
        canvas.height = (tableHeight + padding * 2 + 40) * scale; // Adjusted for the new line and footer
        const ctx = canvas.getContext("2d");
        ctx.scale(scale, scale);
        ctx.drawImage(img, 0, 0);
        const imgUrl = canvas.toDataURL("image/png");
  
        const link = document.createElement("a");
        link.download = fileName;
        link.href = imgUrl;
        link.click();
  
        URL.revokeObjectURL(svgUrl);
      };
      img.src = svgUrl;
    };
  
    return {
      exportTableAsImage
    };
  }