import { defineStore } from 'pinia';
import router from '@/router';
import axios from 'axios';
import { auth } from '@/firebaseConfig';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    isAuthenticated: false,
    loginError: null,
    isInitialized: false,
  }),
  
  getters: {
    currentUser: (state) => state.user,
    authStatus: (state) => state.isAuthenticated,
    loginErrorMessage: (state) => state.loginError,
  },
  
  actions: {
    setUser(user) {
      this.user = user;
      this.isAuthenticated = !!user;
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        localStorage.removeItem('user');
      }
    },
    
    setLoginError(error) {
      console.log('Setting login error', error);
      this.loginError = error;
    },
    
    clearUser() {
      this.user = null;
      this.isAuthenticated = false;
      localStorage.removeItem('user');
    },
    
    async login({ email, password }) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const token = await user.getIdToken();
        
        // Set the token in axios defaults
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        this.setUser(user);
        this.setLoginError(null);
        return { success: true };
      } catch (error) {
        console.error('Login error:', error.code, error.message);
        let errorMessage = 'An unexpected error occurred. Please try again.';
        if (error.code === 'auth/invalid-credential') {
          errorMessage = 'Invalid email or password. Please check your credentials and try again.';
        } else if (error.code === 'auth/user-disabled') {
          errorMessage = 'This account has been disabled. Please contact support.';
        } else if (error.code === 'auth/user-not-found') {
          errorMessage = 'No account found with this email. Please check your email or sign up.';
        }
        this.setLoginError(errorMessage);
        return { success: false, error: errorMessage };
      }
    },
    
    async logout() {
      try {
        await signOut(auth);
        this.clearUser();
        delete axios.defaults.headers.common['Authorization'];
        router.push('/login');
      } catch (error) {
        console.error('Logout error:', error);
      }
    },
    
    initializeAuth() {
        return new Promise((resolve) => {
          const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
              this.setUser(user);
            } else {
              this.clearUser();
            }
            this.isInitialized = true;
            unsubscribe();
            resolve();
          });
        });
      },
    },
  });