import { ref, onUnmounted } from 'vue';
export function useLondonUnixTime() {
    const getLondonDate = (date = new Date()) => {
      const londonTime = new Intl.DateTimeFormat('en-GB', {
        timeZone: 'Europe/London',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(date);
  
      const [datePart, timePart] = londonTime.split(', ');
      const [day, month, year] = datePart.split('/');
      const [hour, minute, second] = timePart.split(':');
      
      return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
    };
  
    const getUnixTimeInThreeHours = () => {
      const now = getLondonDate();
      now.setHours(now.getHours() + 3);
      return getUnixTime(now);
    };
    
    const getUnixTime = (date) => {
      return date.getTime(); // Returns milliseconds
    };
  
    const getLondonUnixTime = () => {
      return getUnixTime(getLondonDate());
    };
  
    const getStartOfDay = (date) => {
      const londonDate = getLondonDate(date);
      londonDate.setUTCHours(0, 0, 0, 0);
      return londonDate;
    };
  
    const getEndOfDay = (date) => {
      const londonDate = getLondonDate(date);
      londonDate.setUTCHours(23, 59, 59, 999);
      return londonDate;
    };
  
    const startOfCurrentDay = () => {
      return getUnixTime(getStartOfDay(new Date()));
    };
  
    const endOfCurrentDay = () => {
      return getUnixTime(getEndOfDay(new Date()));
    };
  
    const startOfYesterday = () => {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return getUnixTime(getStartOfDay(yesterday));
    };
  
    const endOfYesterday = () => {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return getUnixTime(getEndOfDay(yesterday));
    };
  
    // New functions
    const formatDate = (date) => {
      return date.toLocaleString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        timeZone: 'Europe/London'
      });
    };
  
    const getTodayDate = () => {
      return formatDate(getStartOfDay(new Date()));
    };
  
    const getYesterdayDate = () => {
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
      return formatDate(getStartOfDay(yesterday));
    };

    const generateCurrentTimeunix = ref(getLondonUnixTime());
    const updateCurrentTime = () => {
      generateCurrentTimeunix.value = getLondonUnixTime();
    };
    // Update time every minute (60000 milliseconds)
    const intervalId = setInterval(updateCurrentTime, 60000);

    onUnmounted(() => {
      clearInterval(intervalId);
    });
  
    return {
      generateCurrentTimeunix,
      getUnixTimeInThreeHours,
      getLondonUnixTime,
      startOfCurrentDay,
      endOfCurrentDay,
      startOfYesterday,
      endOfYesterday,
      getTodayDate,
      getYesterdayDate
    };
  }