<template>
    <div class="relative">
      <div v-if="loading" class="h-10rem">
        <div class="imab-logo-animated" style="left: 30%; top: 10%;">
          <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
        </div>
      </div> 
      <div v-else>
        <DataTable :value="reactiveAttendance" aria-label="Roster Table">
          <Column field="employee_name" header="Employee" class="text-600 w-3 pl-3">
            <template #body="slotProps">
              <div class="flex items-center gap-2 pl-1">
                <div>
                  <p class="block font-bold pb-1 m-0 p-0">
                    {{ slotProps.data.employee_name }}
                  </p>
                  <p class="block text-xs text-300 m-0 p-0 pb-1">{{ slotProps.data.employee_company_name }}</p>
                  <p class="text-xs text-300 mr-2 m-0 p-0">
                    <span class="mr-2">@{{ slotProps.data.employee_id }}</span>
                    <span class="mr-2">{{ slotProps.data.employee_status }}</span>
                    <span class="mr-2">{{ slotProps.data.employee_department }}</span>
                  </p>
                </div>
              </div>
            </template>
          </Column>
          <Column field="shift_type" header="Type" class="text-600 w-1">
            <template #body="slotProps">
              <div class="items-center gap-2">
                <p class="block pb-1 m-0 p-0">
                  <Tag severity="info" :class="
                        {'bg-yellow-800': slotProps.data.shift_type==='LEAVE', 
                        'bg-cyan-800': slotProps.data.shift_type==='COVER', 
                        'surface-300': slotProps.data.shift_type==='SHIFT',
                        'bg-green-800': slotProps.data.shift_type==='ROSTER' }" style="letter-spacing: 2px;">
                        {{ slotProps.data.shift_type }}
                  </Tag>
                </p>
                <p class="block text-xs text-300 m-0 p-0">ID #{{ slotProps.data.event_id }}</p>
              </div>
            </template>
          </Column>
          <Column field="event_day" header="Day" class="text-600 w-1">
            <template #body="slotProps">
              <div class="items-center gap-2">
                <p class="block pb-1 m-0 p-0">{{ slotProps.data.event_day }}</p>
                <p class="block text-xs text-300 m-0 p-0">{{ slotProps.data.event_month }}</p>
              </div>
            </template>
          </Column>
          <Column field="event_date" header="Date" class="text-600 w-3">
            <template #body="slotProps">
              <Calendar 
                v-model="slotProps.data.event_date" 
                showIcon 
                :manualInput="false"
                :showOnFocus="false"
                dateFormat="yy-mm-dd" 
                :disabled="isSubmittingRoster || isDeletingAttendance || isDeletingBoth"
                @date-select="(e) => handleCalendarDateSelection(e, slotProps.data)"
                aria-label="Start Date"
              />
            </template>
          </Column>
          <Column field="event_start" header="Start" class="text-600 w-2">
            <template #body="slotProps">
                <div class="flex justify-content-start">
                    <div v-if="slotProps.data.event_type==='IN'" class="flex align-items-center justify-content-center mr-2 font-bold surface-100 w-5rem">IN</div>
                    <div v-if="slotProps.data.event_type==='OUT'" class="flex align-items-center justify-content-center mr-2 font-bold surface-100 w-5rem">OUT</div>
                    <div class="flex align-items-center justify-content-center">
                        <Calendar id="calendar-timeonly" 
                            v-model="slotProps.data.event_start" 
                            timeOnly 
                            :disabled="isSubmittingRoster || isDeletingAttendance || isDeletingBoth"
                            aria-label="Start Time"
                            :showSeconds="showSecondsForEvent(slotProps.data.event_type)"
                            :utc="true"/>
                    </div>
                </div>
            </template>
          </Column>
          <Column field="event_end" header="End" class="text-600 w-2 pr-2">
            <template #body="slotProps">
              <div v-if="slotProps.data.event_type==='ROSTER'" class="pr-2">
                <Calendar 
                    id="calendar-timeonly" 
                    v-model="slotProps.data.event_end" 
                    timeOnly 
                    :disabled="isSubmittingRoster || isDeletingAttendance || isDeletingBoth"
                    aria-label="End Time"
                    :showSeconds="showSecondsForEvent(slotProps.data.event_type)"
                    @date-select="(e) => handleEndTimeSelection(e, slotProps.data)"
                    :utc="true"/>
              </div>
              <div v-else>
              <Tag v-if="slotProps.data.event_type==='IN'" severity="info" :class="
                  {'block bg-yellow-800 mb-1': attendanceInStatus==='LATE', 
                  'block bg-cyan-800 mb-1': attendanceInStatus==='EARLY', 
                  'block surface-300 mb-1': attendanceInStatus==='ONTIME',
                  'p-2 mr-3': true}" style="letter-spacing: 2px;">
                  {{ attendanceInStatus }}
              </Tag>
              <Tag v-if="slotProps.data.event_type==='OUT'" severity="info" :class="
                  {'block bg-green-800': attendanceOutStatus==='OVERTIME', 
                  'block bg-red-800': attendanceOutStatus==='EARLY', 
                  'block surface-50 text-xs': attendanceOutStatus==='NOT', 
                  'block surface-300': attendanceOutStatus==='ONTIME',
                  'p-2 mr-3': true}" style="letter-spacing: 2px;">
                  {{ attendanceOutStatus }}
              </Tag>
              </div>
            </template>
          </Column>
        </DataTable>
        <footer class="flex overflow-hidden mt-3">
          <div class="flex-shrink-0 flex align-items-center justify-content-left mr-2">
            <Button type="button" icon="pi pi-trash" @click="toggle" :loading="isDeletingAttendance" :disabled="isSubmittingRoster || isDeletingBoth || isDeletingAttendance" aria-haspopup="true" severity="danger" aria-controls="overlay_menu" />
            <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
          </div>
          <div v-if="hasOvertimeApprovedEntry !== true && hasOutEntry && hasOvertimeEntry" class="flex-shrink-1 flex-grow-1 flex align-items-center justify-content-left mr-2">
            <Button label="Approve Overtime" icon="pi pi-check" @click="updateOvertimeStatus(true)" :loading="isApproving" :disabled="isSubmittingRoster || isDeletingBoth || isDeletingAttendance" type="submit" class="bg-green-700 hover:bg-green-400"/>
          </div>
          <div v-else-if="hasOvertimeApprovedEntry === true && hasOutEntry && hasOvertimeEntry" class="flex-shrink-1 flex-grow-1 flex align-items-center justify-content-left mr-2">
            <Button label="Reject Overtime" icon="pi pi-times" @click="updateOvertimeStatus(false)" :loading="isApproving" :disabled="isSubmittingRoster || isDeletingBoth || isDeletingAttendance" type="submit" class="surface-300 hover:surface-500"/>
          </div>
          <div v-else class="flex-shrink-1 flex-grow-1 flex align-items-center justify-content-left mr-2">
            <Button label="Approve Overtime" icon="pi pi-check" disabled  type="submit" class="surface-300 hover:surface-500"/>
          </div>
          <div class="flex-shrink-0 flex align-items-center justify-content-end mr-2">
            <Button label="Cancel" class="w-full surface-200 hover:surface-400" @click="handleBack" :disabled="isSubmittingRoster || isDeletingAttendance || isDeletingBoth" aria-label="Cancel and go back" />
          </div>
          <div class="flex-shrink-0 flex align-items-center justify-content-end">
            <Button label="Save Attendance" severity="primary" type="submit" @click="submitForm" :loading="isSubmittingRoster" :disabled="isSubmittingRoster || isDeletingAttendance || isDeletingBoth" class="w-full hover:bg-primary-300" aria-label="Edit selected rosters" />
          </div>
        </footer>
      </div>
    </div>
</template>

<script setup>
import { onMounted, watch, defineProps, defineEmits, computed, ref } from 'vue';
import { useAttendanceSingle } from "@/composables/api/useAttendanceSingle";
import { useAttendanceSingleUpdate } from "@/composables/api/useAttendanceSingleUpdate";
import { useAttendanceSingleDelete } from "@/composables/api/useAttendanceSingleDelete";
import { useAttendanceSingleDeleteBoth } from "@/composables/api/useAttendanceSingleDeleteBoth";
import { useAttendanceSingleApprove } from '@/composables/api/useAttendanceSingleApprove';
import { useUtils } from '@/composables/useUtils';
import { useToast } from 'primevue/usetoast';
import Menu from 'primevue/menu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Tag from 'primevue/tag';
import Button from 'primevue/button';

const props = defineProps({
  selectedIds: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['updateSuccess', 'deleteSuccess', 'handleBack']);

const menu = ref();
const items = computed(() => {
  const baseItems = [
    {
      label: 'Delete Attendance',
      command: () => {
        deleteAttendance();
      }
    },
    {
      label: 'Delete Attendance and Roster',
      command: () => {
        deleteBoth();
      }
    }
  ];

  if (hasOutEntry.value) {
    baseItems.unshift({
      label: 'Delete Logout Entry',
      command: () => {
        deleteLogoutOnly();
      }
    });
  }

  return [
    {
      label: 'Options',
      items: baseItems
    }
  ];
});

const toggle = (event) => {
    menu.value.toggle(event);
};

const { 
  getExactTimestamp,
  updateDateInfo,
  compareTimestamps,
  parseUTCDate
} = useUtils();

const { singleRoster, loading, fetchSingleAttendance } = useAttendanceSingle();
const { isSubmittingRoster, submitBulkUpdate } = useAttendanceSingleUpdate();
const { isDeletingBoth, deleteBoth: deleteBothApi } = useAttendanceSingleDeleteBoth();
const { isDeleting: isDeletingAttendance, deleteAttendance: deleteAttendanceApi } = useAttendanceSingleDelete();
const { isApproving, updateOverBelowStatus } = useAttendanceSingleApprove();


const reactiveAttendance = ref([]);
const toast = useToast();

const event_shift_start = ref(null);
const event_start = ref(null);
const event_end = ref(null);
const event_shift_end = ref(null);

// Computed properties
const showSecondsForEvent = (eventType) => {
  return eventType !== 'ROSTER';
};

const attendanceInStatus = computed(() => {
  return compareTimestamps(event_start.value, event_shift_start.value, 'IN');
});

const attendanceOutStatus = computed(() => {
  return compareTimestamps(event_end.value, event_shift_end.value, 'OUT');
});

const hasOutEntry = computed(() => {
  return reactiveAttendance.value.some(event => event.event_type === 'OUT');
});

const hasOvertimeEntry = computed(() => {
  return reactiveAttendance.value.some(event => event.attendance_status === 'OVERTIME');
});

const hasOvertimeApprovedEntry = computed(() => {
  return reactiveAttendance.value.some(event => event.over_below_status === true);
});

const handleEndTimeSelection = (newTime, roster) => {
  // Extract hours and minutes from the new time
  const newEndHours = newTime.getHours();
  const newEndMinutes = newTime.getMinutes();

  // Get the start time
  const startHours = roster.event_start.getHours();
  const startMinutes = roster.event_start.getMinutes();

  // Create a new end date based on the start date
  const newEndDate = new Date(roster.event_start);
  newEndDate.setHours(newEndHours, newEndMinutes, 0, 0);

  // Calculate the time difference in minutes
  const timeDiff = (newEndHours * 60 + newEndMinutes) - (startHours * 60 + startMinutes);

  // If the end time is earlier than or equal to the start time, it's the next day
  if (timeDiff <= 0) {
    newEndDate.setDate(newEndDate.getDate() + 1);
  }

  // Update the roster with the new end date
  roster.event_end = newEndDate;
};

const handleCalendarDateSelection = (newDate, roster) => {
  const currentDate = new Date(roster.event_start);
  currentDate.setUTCFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  roster.event_start = currentDate;
  roster.event_end = new Date(roster.event_end);
  roster.event_end.setUTCFullYear(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  updateDateInfo(roster);
};

const updateIndividualTimestamps = () => {
  const rosterEvent = reactiveAttendance.value.find(event => event.event_type === 'ROSTER');
  const inEvent = reactiveAttendance.value.find(event => event.event_type === 'IN');
  const outEvent = reactiveAttendance.value.find(event => event.event_type === 'OUT');

  event_shift_start.value = rosterEvent?.event_start ?? null;
  event_shift_end.value = rosterEvent?.event_end ?? null;
  event_start.value = inEvent?.event_start ?? null;
  event_end.value = outEvent?.event_start ?? null;
};

const loadRosterData = async () => {
  try {
    await fetchSingleAttendance(props.selectedIds.employee_id, props.selectedIds.roster_id, props.selectedIds.attendance_id);
    reactiveAttendance.value = singleRoster.value.map(roster => {
      const startDate = parseUTCDate(roster.event_start);
      const endDate = parseUTCDate(roster.event_end);
      
      return {
        ...roster,
        event_start: startDate,
        event_end: endDate,
        event_date: startDate,
        event_month: startDate.toLocaleString('default', { month: 'long', timeZone: 'UTC' }),
        event_day: startDate.toLocaleString('default', { weekday: 'long', timeZone: 'UTC' })
      };
    });
    setupWatchers();
    updateIndividualTimestamps();
  } catch (error) {
    console.error('Error fetching roster data:', error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to load roster data', life: 12000, group: 'br'});
  }
};

// Submit Attendance Form
const submitForm = async () => {
  const rosterPayload = { rows: [] };
  const inPayload = { rows: [] };
  const outPayload = { rows: [] };

  reactiveAttendance.value.forEach(event => {
    switch(event.event_type) {
      case 'ROSTER':
        rosterPayload.rows.push({
          rosterId: event.event_id,
          employeeId: event.employee_id,
          rosterDay: event.event_day,
          rosterMonth: event.event_month,
          rosterStart: getExactTimestamp(event.event_start),
          rosterEnd: getExactTimestamp(event.event_end)
        });
        break;
      case 'IN':
        inPayload.rows.push({
          attendanceId: event.event_id,
          employeeId: event.employee_id,
          attendanceInOut: getExactTimestamp(event.event_start),
          attendanceStatus: attendanceInStatus.value
        });
        break;
      case 'OUT':
        outPayload.rows.push({
          attendanceId: event.event_id,
          employeeId: event.employee_id,
          attendanceInOut: getExactTimestamp(event.event_start),
          attendanceStatus: attendanceOutStatus.value
        });
        break;
    }
  });

  const result = await submitBulkUpdate(rosterPayload, inPayload, outPayload);

  if (result.success) {
    toast.add({ 
      severity: result.severity, 
      summary: result.summary, 
      detail: result.messages.join('. '), 
      life: 4000,
      group: 'br'
    });
    emit('updateSuccess');
  } else {
    toast.add({ 
      severity: result.severity, 
      summary: result.summary, 
      detail: result.error, 
      life: 12000,
      group: 'br'
    });
  }
};

// Delete Attendance and Roster
const deleteBoth = async () => {
  const rosterPayload = { rosterIds: [], employeeIds: [] };
  const attendancePayload = [];

  reactiveAttendance.value.forEach(event => {
    if (event.event_type === 'ROSTER') {
      rosterPayload.rosterIds.push(event.event_id);
      rosterPayload.employeeIds.push(event.employee_id);
    } else if (event.event_type === 'IN') {
      if (!attendancePayload.some(item => item.attendanceId === event.event_id)) {
        attendancePayload.push({ attendanceId: event.event_id, employeeId: event.employee_id });
      }
    }
  });

  const result = await deleteBothApi(rosterPayload, attendancePayload);

  if (result.success) {
    toast.add({
      severity: result.severity,
      summary: result.summary,
      detail: result.message,
      life: 4000,
      group: 'br'
    });
    emit('updateSuccess');
  } else {
    toast.add({
      severity: result.severity,
      summary: result.summary,
      detail: result.error,
      life: 4000,
      group: 'br'
    });
  }
};

// Delete Attendance Only
const deleteAttendance = async () => {
  const attendancePayload = [];

  reactiveAttendance.value.forEach(event => {
    if (event.event_type === 'IN') {
      if (!attendancePayload.some(item => item.attendanceId === event.event_id)) {
        attendancePayload.push({ attendanceId: event.event_id, employeeId: event.employee_id });
      }
    }
  });

  const result = await deleteAttendanceApi(attendancePayload);

  toast.add({
    severity: result.severity,
    summary: result.summary,
    detail: result.success ? result.message : result.error,
    life: 4000,
    group: 'br'
  });

  if (result.success) {
    emit('updateSuccess');
  }
};

// Delete Attendance Logout Entry
const deleteLogoutOnly = async () => {
  const attendancePayload = [];

  reactiveAttendance.value.forEach(event => {
    if (event.event_type === 'OUT') {
      if (!attendancePayload.some(item => item.attendanceId === event.event_id)) {
        attendancePayload.push({ attendanceId: event.event_id, employeeId: event.employee_id });
      }
    }
  });

  const result = await deleteAttendanceApi(attendancePayload);

  toast.add({
    severity: result.severity,
    summary: result.summary,
    detail: result.success ? result.message : result.error,
    life: 4000,
    group: 'br'
  });

  if (result.success) {
    emit('updateSuccess');
  }
};

// Delete Attendance Only
const updateOvertimeStatus = async (status) => {
  const attendancePayload = reactiveAttendance.value
    .filter(event => event.event_type === 'IN')
    .map(event => ({
      overBelowStatus: status,
      attendanceId: event.event_id,
      employeeId: event.employee_id
    }));

  const result = await updateOverBelowStatus(attendancePayload);

  toast.add({
    severity: result.severity,
    summary: result.summary,
    detail: result.success ? result.message : result.error,
    life: 4000,
    group: 'br'
  });

  if (result.success) {
    emit('updateSuccess');
  }
};

const setupWatchers = () => {
  reactiveAttendance.value.forEach((roster, index) => {
    watch(() => reactiveAttendance.value[index], updateIndividualTimestamps, { deep: true });
  });
};

watch(reactiveAttendance, updateIndividualTimestamps, { deep: true });

const handleBack = async () => {
  emit('handleBack');
};

onMounted(loadRosterData);
watch(() => props.selectedIds, loadRosterData);
</script>