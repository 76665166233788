// composables/useRoster.js
import { ref, computed, onMounted } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

const ROSTER_STORAGE_KEY = 'allRosters';

export function useRoster() {
  const allRosters = ref([]);
  const rosterLoading = ref(false);
  const rosterErrors = ref(null);

  const safeAllRosters = computed(() => {
    return Array.isArray(allRosters.value) ? allRosters.value : [];
  });

  const setRosters = (rosters) => {
    allRosters.value = Array.isArray(rosters) ? rosters : [];
    localStorage.setItem(ROSTER_STORAGE_KEY, JSON.stringify(allRosters.value));
  };

  const handleError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      return err.response.data.message;
    }
    return 'An unexpected error occurred. Please try again later.';
  };

  const fetchAllRosters = async (employeeId, companyId, targetDate) => {
    rosterLoading.value = true;
    rosterErrors.value = null;

    try {
      const url = API_ENDPOINTS.fetchRosters
        .replace('{employeeId}', employeeId)
        .replace('{companyId}', companyId)
        .replace('{targetDate}', targetDate);

      const response = await axiosClient.get(url);
      
      if (response.data && Array.isArray(response.data)) {
        setRosters(response.data);
      } else {
        console.warn('Received non-array data from API:', response.data);
        setRosters([]);
      }
    } catch (err) {
      rosterErrors.value = handleError(err);
      setRosters([]);
    } finally {
      rosterLoading.value = false;
    }
  };

  const initializeFromLocalStorage = () => {
    const storedRosters = localStorage.getItem(ROSTER_STORAGE_KEY);
    if (storedRosters) {
      try {
        const parsedRosters = JSON.parse(storedRosters);
        allRosters.value = Array.isArray(parsedRosters) ? parsedRosters : [];
      } catch (error) {
        console.error('Error parsing stored rosters:', error);
        allRosters.value = [];
      }
    }
  };

  onMounted(() => {
    initializeFromLocalStorage();
  });

  return {
    allRosters: safeAllRosters,
    rosterLoading,
    rosterErrors,
    fetchAllRosters
  };
}