// composables/api/useAttendanceSingleDelete.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import axios from 'axios';

export function useAttendanceSingleDelete() {
  const isDeleting = ref(false);

  const deleteAttendance = async (attendancePayload) => {
    isDeleting.value = true;

    try {
      if (attendancePayload.length > 0) {
        const response = await axiosClient.post(API_ENDPOINTS.deleteAttendance, attendancePayload, {
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        });

        if (response.status === 200) {
          return {
            success: true,
            message: 'Attendance deleted successfully',
            severity: 'success',
            summary: 'Success'
          };
        } else {
          throw new Error('Delete attendance request failed');
        }
      } else {
        return {
          success: true,
          message: 'No attendance records to delete',
          severity: 'info',
          summary: 'Info'
        };
      }
    } catch (error) {
      console.error('Error deleting attendance:', error);
      let errorMessage = 'Failed to delete attendance';
      let severity = 'error';
      let summary = 'Error';

      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          errorMessage = `Server error: ${error.response.status}`;
          
          // Adjust severity based on error code if needed
          switch (error.response.data.code) {
            case 'VALIDATION_ERROR':
            case 'INVALID_FORMAT':
              severity = 'warn';
              summary = 'Warning';
              break;
            case 'RECORD_NOT_FOUND':
              severity = 'info';
              summary = 'Information';
              break;
          }
        } else if (error.request) {
          console.error('Error request:', error.request);
          errorMessage = 'No response from server';
        } else {
          console.error('Error message:', error.message);
          errorMessage = error.message;
        }
      } else {
        // Handle non-Axios errors
        errorMessage = error.message || 'An unexpected error occurred';
      }

      return {
        success: false,
        error: errorMessage,
        severity,
        summary
      };
    } finally {
      isDeleting.value = false;
    }
  };

  return {
    isDeleting,
    deleteAttendance
  };
}