// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCJn3h5MELpX5UQS3MlWmWVTCgkp_FfDnk",
    authDomain: "imab-auth.firebaseapp.com",
    projectId: "imab-auth",
    storageBucket: "imab-auth.appspot.com",
    messagingSenderId: "96774304840",
    appId: "1:96774304840:web:7189c0a079ba513077547d",
    measurementId: "G-2C5FVQY1M6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };