// useUser.js
import { computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';

export function useUser() {
  const authStore = useAuthStore();
  const { user, isAuthenticated } = storeToRefs(authStore);

  const userId = computed(() => user.value ? user.value.uid : null);

  const userEmail = computed(() => user.value ? user.value.email : null);

  const userName = computed(() => {
    if (user.value) {
      return user.value.displayName || user.value.email.split('@')[0];
    }
    return null;
  });

  return { 
    user,
    isAuthenticated,
    userId,
    userEmail,
    userName
  };
}