// stores/userSelectionStore.js
import { defineStore } from 'pinia'

export const useUserSelectionStore = defineStore('userSelection', {
  state: () => ({
    store_employee_id: null,
    store_employee_company_id: null
  }),
  actions: {
    setEmployeeId(id) {
      this.store_employee_id = id
    },
    setCompanyId(id) {
      this.store_employee_company_id = id
    }
  }
})