// composables/useClearData.js
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export function useClearData() {
  const isClearingData = ref(false);
  const router = useRouter();

  async function clearDataAndRefresh() {
    isClearingData.value = true;
    try {
      // Remove specified keys from localStorage
      const keysToRemove = [
        'employeesList',
        'companiesList',
        'allRosters',
        'attendanceData'
      ];

      keysToRemove.forEach(key => localStorage.removeItem(key));

      // Redirect to root page
      await router.push('/');

      // Refresh the page
      window.location.reload();
    } catch (error) {
      console.error('Error during data clearing and refresh:', error);
    } finally {
      isClearingData.value = false;
    }
  }

  return {
    isClearingData,
    clearDataAndRefresh
  };
}