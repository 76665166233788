import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useFetchUpcomingEmployees(startDate, endDate) {
    const data = ref(null);
    const error = ref(null);
    const loading = ref(false);

    async function fetchUpcomingEmployees() {
        loading.value = true;
        try {
            const url = API_ENDPOINTS.fetchUpcomingEmployees
                .replace('{startDate}', startDate)
                .replace('{endDate}', endDate);
            
            const response = await axiosClient.get(url);
            data.value = response.data;
        } catch (err) {
            console.error('Error fetching upcoming employees:', err);
            error.value = 'Failed to fetch upcoming employees';
            if (axiosClient.isAxiosError(err)) {
                if (err.response) {
                    console.error('Error response:', err.response.data);
                    console.error('Error status:', err.response.status);
                    error.value = `Server error: ${err.response.status}`;
                } else if (err.request) {
                    console.error('Error request:', err.request);
                    error.value = 'No response from server';
                } else {
                    console.error('Error message:', err.message);
                    error.value = err.message;
                }
            }
        } finally {
            loading.value = false;
        }
    }

    return { data, error, loading, fetchUpcomingEmployees };
}