// composables/api/useLogsCreate.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import axios from 'axios';

export function useLogsCreate() {
  const isSubmitting = ref(false);

  const submitCreateLog = async (logData) => {
    if (isSubmitting.value) return { success: false, message: 'Submission already in progress' };
    isSubmitting.value = true;

    try {
      const response = await axiosClient.post(API_ENDPOINTS.createLog, logData, {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 10000,
      });

      return {
        success: true,
        message: response.data.message || 'Log created successfully'
      };
    } catch (error) {
      console.error('Error creating log:', error);
      let errorMessage = 'Failed to create log';
      let severity = 'error';

      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          errorMessage = error.response.data.error || `Server error: ${error.response.status}`;
          
          if (error.response.status === 400 || error.response.status === 409) {
            severity = 'warn';
          }
        } else if (error.request) {
          console.error('Error request:', error.request);
          errorMessage = 'No response from server';
        } else {
          console.error('Error message:', error.message);
          errorMessage = error.message;
        }
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      return {
        success: false,
        message: errorMessage,
        severity: severity
      };
    } finally {
      isSubmitting.value = false;
    }
  };

  return {
    isSubmitting,
    submitCreateLog
  };
}