// composables/api/useRosterBulkDelete.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import axios from 'axios';

export function useRosterBulkDelete() {
  const isDeleting = ref(false);

  const bulkDeleteRoster = async (rosterIds, employeeIds) => {
    if (isDeleting.value) return { success: false, message: 'Delete operation already in progress' };
    isDeleting.value = true;

    try {
      // Validation
      if (rosterIds.length === 0 || employeeIds.length === 0) {
        throw new Error('No roster entries selected for deletion.');
      }

      // Check for any undefined or null values
      if (rosterIds.some(id => id == null) || employeeIds.some(id => id == null)) {
        throw new Error('Invalid data: Some entries are missing required IDs.');
      }

      const payload = { rosterIds, employeeIds };

      const response = await axiosClient.post(API_ENDPOINTS.bulkDeleteRoster, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 10000,
      });

      return {
        success: true,
        message: response.data.message || 'Successfully deleted roster entries'
      };
    } catch (error) {
      console.error('Error deleting rosters:', error);
      let errorMessage = 'Failed to delete rosters';
      let severity = 'error';

      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          errorMessage = error.response.data.error || `Server error: ${error.response.status}`;
          
          if (error.response.status === 400 || error.response.status === 409) {
            severity = 'warn';
          }
        } else if (error.request) {
          console.error('Error request:', error.request);
          errorMessage = 'No response from server';
        } else {
          console.error('Error message:', error.message);
          errorMessage = error.message;
        }
      } else {
        errorMessage = error.message || 'An unexpected error occurred';
      }

      return {
        success: false,
        message: errorMessage,
        severity: severity
      };
    } finally {
      isDeleting.value = false;
    }
  };

  return {
    isDeleting,
    bulkDeleteRoster
  };
}