<!-- views/EmployeeCard.vue -->
<template>
  <div :class="{
              'alert': calculateTimeRemaining(generateCurrentTimeunix, rosterLog.event_start) <= 10 
                          && calculateTimeRemaining(generateCurrentTimeunix, rosterLog.event_start) > 3,
              'alert2': title =='UPCOMING' && calculateTimeRemaining(generateCurrentTimeunix, rosterLog.event_start) <= 3,
              'warning': title ==='LEAVES' && (rosterLog.event_type == 'SHIFT' || rosterLog.event_type == 'COVER'),
              'notapproved': title ==='OVERTIME' && rosterLog.over_below_status !== true,
              'early2': title ==='EARLY' && (rosterLog.attendance_status_in == 'EARLY' && rosterLog.event_type == 'SHIFT'),
              'employee-card flex w-15rem': true}">
    <div class="absolute bottom-0 right-0 z-1 shortcut">
      <div class="flex justify-content-end flex-wrap">
          <div class="flex align-items-center justify-content-center">
            <Button icon="pi pi-pencil" class="surface-300 hover:surface-500" @click="openDialog(title)" />
            <Button icon="pi pi-code" class="surface-300 hover:surface-500" @click="openDialog('LOGS')" />
            <Button icon="pi pi-sitemap" class="surface-300 hover:surface-500" @click="openDialog('PROFILE', rosterLog.employee_id, rosterLog.employee_company_id)" />
          </div>
      </div>
    </div>
    <div class="employee-photo-small">
      <div class="employee-cover-status" v-if="rosterLog.shift_type_in === 'COVER' || rosterLog.event_type === 'COVER'">{{ rosterLog.shift_type_in || rosterLog.event_type }}</div>
      <div class="employee-overtime-status" v-if="title ==='OVERTIME' && rosterLog.attendance_out > 0 && rosterLog.over_below_status !== true">REVIEW</div>
      <img :src="rosterLog.employee_photo_url || defaultUserImage" alt="Employee Photo"/>
    </div>
    <div class="employee-content">
      <h3 class="employee-name">{{ rosterLog.employee_name.length > 21 ? rosterLog.employee_name.substring(0, 21) + '...' : rosterLog.employee_name }}</h3>
      <p class="employee-shifttime" v-if="title=='COVERS'">{{ rosterLog.employee_company_name.length > 21 ? rosterLog.employee_company_name.substring(0, 21) + '...' : rosterLog.employee_company_name }}</p>
      <p class="employee-checkintime" v-if="title !='UPCOMING' && title !='LEAVES'">{{ formatTime(rosterLog.attendance_in) }}
            <span v-if="title !== 'NOTLOGOUT' && rosterLog.attendance_out != null">to {{ formatTime(rosterLog.attendance_out) }}</span>
            <span v-else-if="title !== 'NOTLOGOUT' && rosterLog.attendance_out == null" style="opacity: 0.4; font-size: 10px; letter-spacing: 0;">ON GOING</span>
            <span v-else-if="title == 'NOTLOGOUT' && rosterLog.attendance_out == null" style="opacity: 0.4; font-size: 10px; letter-spacing: 0;">NOT LOGOUT</span>
      </p>
      <p class="employee-shifttime">{{ formatTime(rosterLog.event_start) }} to {{ formatTime(rosterLog.event_end) }}</p>
      <div v-if="rosterLog.attendance_status_in==='EARLY'">
        <p class="employee-timecounter" v-if="showCounter==true && title=='EARLY'">+{{ calculateTimeRemaining(rosterLog.attendance_in, rosterLog.event_start, 'm') }}</p>
      </div>
      <div v-else-if="rosterLog.attendance_status_out==='EARLY'">
        <p class="employee-timecounter" v-if="showCounter==true && title=='EARLY'">-{{ calculateTimeRemaining(rosterLog.attendance_out, rosterLog.event_end, 'm') }}</p>
      </div>
      <p class="employee-timecounter" v-else-if="showCounter==true && title=='ONTIME'">+{{ calculateTimeRemaining(rosterLog.attendance_in, rosterLog.event_start, 'm') }}</p>
      <p class="employee-timecounter" v-else-if="showCounter==true && title=='LATE'">-{{ calculateTimeRemaining(rosterLog.event_start, rosterLog.attendance_in, 'm') }}</p>
      <p class="employee-timecounter" v-else-if="showCounter==true && title=='OVERTIME'">+{{ calculateTimeDifferenceInHours(rosterLog.event_end, rosterLog.attendance_out, 'h') }}</p>
      <p class="employee-timecounter" v-if="showCounter && title === 'UPCOMING'">{{ displayCountdown }}</p>
      <p class="employee-timecounter" v-else-if="showCounter==true && title=='NOTLOGOUT'">{{ calculateTimeRemaining(rosterLog.event_end, generateCurrentTimeunix, 'm') }}</p>
      <p class="employee-timecounter workedcounter" v-else-if="showCounter==true && rosterLog.attendance_out !== null && title !='LEAVES'">{{ calculateTimeDifferenceInHours(rosterLog.attendance_in, rosterLog.attendance_out, 'h') }}</p>
      <p class="employee-timecounter workedcounter" v-else-if="showCounter==true && rosterLog.attendance_out == null && title !='LEAVES'">ONGOING</p>
      <p class="employee-timecounter" v-else-if="title =='LEAVES' && rosterLog.event_type !== 'SHIFT' && rosterLog.event_type !== 'COVER'">LEAVE</p>
      <p class="employee-timecounter" v-else-if="title =='LEAVES' && (rosterLog.event_type == 'SHIFT' || rosterLog.event_type == 'COVER')">NOSHOW</p>
    </div>
  </div>

  <Dialog v-model:visible="editRosterDialog" :style="{ width: '60vw' }" :modal="true" maximizable>
    <template #header>
      <h3>Roster Edit</h3>
    </template>
    <RosterUpdateDialog :selectedRosterIds="[rosterLog.event_id]" @updateSuccess="handleUpdateSuccess" @handleBack="handleBack" />
  </Dialog>

  <Dialog v-model:visible="editAttendanceDialog" :style="{ width: '60vw' }" :modal="true" maximizable>
    <template #header>
      <h3>Attendance Edit</h3>
    </template>
    <AttendanceUpdateDialog :selectedIds="{ employee_id: rosterLog.employee_id, roster_id: rosterLog.event_id, attendance_id: rosterLog.attendance_id_in}" 
                            @updateSuccess="handleUpdateSuccess" @handleBack="handleBack" />
  </Dialog>

  <Dialog v-model:visible="viewLogs" :style="{ width: '60vw' }" :modal="true">
    <template #header>
      <h2>Logs</h2>
    </template>
    <LogsDialog :selectedIds="rosterLog.event_id" logType="roster" @handleBack="handleBack" />
  </Dialog>
</template>

<script setup>
import { ref, computed, onUnmounted, defineEmits, defineProps, watchEffect } from 'vue';
import { useTimeCalculation } from '@/composables/time/useTimeCalculation';
import { useLondonUnixTime } from '@/composables/time/useLondonTime';
import { useUserSelectionStore } from '@/stores/userSelectionStore';
import { useRouter } from 'vue-router';
import LogsDialog from '@/components/dialogs/LogsDialog.vue';
import RosterUpdateDialog from '@/components/dialogs/RosterUpdateDialog.vue';
import AttendanceUpdateDialog from '@/components/dialogs/AttendanceUpdateDialog.vue';
import defaultUserImage from '@/assets/default-user.png';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

const router = useRouter();
const userSelectionStore = useUserSelectionStore();
const { generateCurrentTimeunix } = useLondonUnixTime();

const editRosterDialog = ref(false);
const editAttendanceDialog = ref(false);
const viewLogs = ref(false);

// Props
const props = defineProps({
  title: String,
  rosterLog: Object,
  showCounter: Boolean,
  loadingIndicator: Boolean,
  attendanceLoadingIndicator: Boolean
});

const { calculateTimeRemaining, formatTime, calculateTimeDifferenceInHours } = useTimeCalculation()


// openDialog function
const openDialog = (title, employee_id = null, employee_company_id = null) => {
  if (title === 'PROFILE' && employee_id > 0) {
    userSelectionStore.setEmployeeId(employee_id);
    userSelectionStore.setCompanyId(employee_company_id);
    router.push('/profiles');
  } else if (title === 'LOGS') {
    viewLogs.value = true;
  } else {
    const dialogRef = title === "UPCOMING" || title === "LEAVES" ? editRosterDialog : editAttendanceDialog;
    dialogRef.value = true;
  }
};

const countdownValue = ref('00:00:00');
let intervalId = null;

// Computed properties for better performance
const isUpcoming = computed(() => props.title === 'UPCOMING');
const shouldShowCounter = computed(() => props.showCounter && isUpcoming.value);

const updateCountdown = () => {
  if (shouldShowCounter.value && props.rosterLog) {
    countdownValue.value = calculateTimeRemaining(generateCurrentTimeunix, props.rosterLog.event_start, 'm');
  }
};

const resetAndStartCountdown = () => {
  clearInterval(intervalId);
  updateCountdown();
  intervalId = setInterval(updateCountdown, 60000);
};

// Use watchEffect for more concise reactivity
watchEffect(() => {
  if (shouldShowCounter.value) {
    resetAndStartCountdown();
  } else {
    clearInterval(intervalId);
  }
});

onUnmounted(() => clearInterval(intervalId));

// For displaying the countdown in your template
const displayCountdown = computed(() => shouldShowCounter.value ? countdownValue.value : '');

// Emits
const emit = defineEmits(['updateSuccess']);

const handleUpdateSuccess = () => {
  emit('updateSuccess');
  editRosterDialog.value = false;
  editAttendanceDialog.value = false;
  viewLogs.value = false;
  resetAndStartCountdown();
};

const handleBack = () => {
  editRosterDialog.value = false;
  editAttendanceDialog.value = false;
  viewLogs.value = false;
};
</script>


<style scoped>
.dashboard-sections .employee-card {
  opacity: 1;
}
.dashboard-sections .employee-card:hover {
  background-color: #494949;
  cursor: pointer;
}
.dashboard-sections .employee-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #666;
  border-radius: 2px;
  margin-bottom: 4px;
  height: 55px;
}
.dashboard-sections .upcoming .employee-card, .dashboard-sections .leaves .employee-card{
  height: 46px;
}
.dashboard-sections .upcoming .employee-content, .dashboard-sections .leaves .employee-content{
  padding-top: 8px;
}
.dashboard-sections .employee-photo-small img {
  width: 43px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 3px 0px 0 3px;
}
.dashboard-sections .employee-photo-small {
  margin-right: 8px;
}
.dashboard-sections .employee-content {
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 12px;
  letter-spacing: 2px;
  color: #ffffffc9;
}
.dashboard-sections .employee-name {
  margin: 0 0 3px 0;
  padding: 0;
  font-size: 11px;
  letter-spacing: 0;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffffc9;
}
.dashboard-sections .employee-checkintime {
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.dashboard-sections .employee-shifttime {
  margin: 0;
  padding: 0;
  opacity: 0.5;
  font-weight: bold;
}
.dashboard-sections .employee-overtimestatus {
  margin: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 28px;
  font-size: 8px;
  padding: 4px 6px;
  background-color: #000;
  text-transform: uppercase;
}
.dashboard-sections .employee-noshowstatus {
  margin: 0;
  padding: 0;
  opacity: 0.5;
  position: absolute;
  right: -1px;
  top: 28px;
  font-size: 8px;
  padding: 4px 3px;
  background-color: #000;
}
.dashboard-sections .employee-cover-status{
  position: absolute;
  letter-spacing: 2px;
  background-color: #00caa5;
  bottom: 0;
  font-size: 8px;
  padding: 0px 4px;
  color: #000;
  font-weight: bold;
}
.dashboard-sections .employee-overtime-status{
  position: absolute;
  letter-spacing: 1px;
  background-color: #f69700;
  bottom: 0;
  font-size: 8px;
  padding: 0px 2px;
  color: #000;
  font-weight: bold;
}

/* Style card as per status */
.dashboard-sections .loadingIndicator .employee-card, .dashboard-sections .attendanceLoadingIndicator .employee-card{
  cursor: default;
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(1);
}

.dashboard-sections .employee-timecounter {
  position: absolute;
    width: auto;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #ffffff9b;
    font-weight: bold;
    bottom: 0;
    right: 0;
    text-align: right;
    background-color: #00000029;
    padding: 4px 4px 2px 6px;
    border-radius: 4px;
    font-size: 10px;
    letter-spacing: 0;
}

.dashboard-sections .workedcounter{
  bottom: 16px;
}
.dashboard-sections .covers .workedcounter{
  bottom: 0;
}
.dashboard-sections .covers .employee-card{
  height: 66px;
}

.dashboard-sections .upcoming .warning{
  background-color: #906b0c;
}
.dashboard-sections .upcoming .warning:hover{
  background-color: #c48d00;
}





.dashboard-sections .alert{
  background-color: #58602f !important;
}
.dashboard-sections .alert:hover{
  background-color: #667712 !important;
}
.dashboard-sections .alert2{
  background-color: #692000 !important;
}
.dashboard-sections .alert2:hover{
  background-color: #ac3400 !important;
}

.dashboard-sections .ontime .employee-card{
  background-color: #466c46;
}
.dashboard-sections .ontime .employee-card:hover{
  background-color: #227c22;
}
.dashboard-sections .late .employee-card{
  background-color: #6e4a24;
}
.dashboard-sections .late .employee-card:hover{
  background-color: #c46500;
}
.dashboard-sections .leaves .employee-card{
  background-color: #65466c;
}
.dashboard-sections .leaves .employee-card:hover{
  background-color: #ae15d0;
}
.dashboard-sections .warning{
  background-color: rgb(137, 4, 4) !important;
}
.dashboard-sections .warning:hover{
  background-color: rgb(206, 12, 12) !important;
}
.dashboard-sections .overtime .employee-card{
  background-color: #46516c;
}
.dashboard-sections .overtime .employee-card:hover{
  background-color: #1748c6;
}
.dashboard-sections .overtime .notapproved{
  background-color: #363636 !important;
}
.dashboard-sections .overtime .notapproved:hover{
  background-color: #1748c6 !important;
}
.dashboard-sections .early .employee-card{
  background-color: #9a392b;
}
.dashboard-sections .early .employee-card:hover{
  background-color: #891200;
}
.dashboard-sections .early2{
  background-color: #6f372f !important;
}
.dashboard-sections .early2:hover{
  background-color: #7b1000 !important;
}
.dashboard-sections .notlogout .employee-card{
  background-color: #5d2c3e;
  animation: opacityAnimation 2s infinite ease-in-out;
}
.dashboard-sections .notlogout .employee-card:hover{
  background-color: rgb(133, 38, 105);
}
.dashboard-sections .covers .employee-card{
  background-color: #107161;
}
.dashboard-sections .covers .employee-card:hover{
  background-color: #00a186;
}
.p-button{
    width: 1rem;
    height: 1rem;
    border-radius: 3px;
    padding: 10px 13px;
    margin-bottom: 3px;
    margin-right: 2px;
    background-color: rgba(255, 255, 255, 0.314) !important
}
.dashboard-sections .employee-card .shortcut{
  display: none;
}
.dashboard-sections .employee-card:hover .shortcut{
  display: block;
}
.dashboard-sections .employee-card:hover .employee-timecounter{
  display: none;
}

</style>

<style>

.dashboard-sections .employee-card .p-button-icon{
  font-size: 12px !important;
}
</style>