<template>
  <div class="flex align-items-center justify-content-center min-h-screen">
    <div class="surface-card p-6 border-round w-full lg:w-26rem">
      <div class="text-center mt-2 mb-2">
        <img :src="logoImage" alt="Image" height="110" class="mb-2" />
        <h2 class="text-400 text-2xl font-light m-0 p-0">Welcome</h2>
      </div>
      <div class="mb-3" v-focustrap>
        <form @submit.prevent="login">
          <div>
            <label for="email" class="text-sm text-300 block mb-1">Email Address</label>
            <InputText id="email" type="email" v-model="email" class="w-full mb-3" required="" autocomplete="off" autofocus />
          </div>
          <div>
            <label for="password" class="text-sm text-300 block mb-1">Password</label>
            <InputText id="password" type="password" v-model="password" class="w-full mb-3" required="" />
          </div>
          <Button :label="loginButton ? 'Logging in...' : 'Login'" :disabled="loginButton" :loading="loginButton" icon="pi pi-user" class="w-full" type="submit"/>
          <div v-if="loginErrorMessage" class="text-sm text-orange-500 mt-4">{{ loginErrorMessage }}</div>
        </form>
      </div>
      <p class="text-400 text-xs">If you forgot your password, Please contact the admin.</p>
      <div class="text-center">
        <div class="imab-logo mt-4 mb-2">
          <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
          <span class="text-sm text-400" style="letter-spacing: .4rem;">core</span>
        </div>
        <span class="text-xs text-300">{{ envName }} {{ envVersion }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { ref, computed, onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import logoImage from '@/assets/orion-logo-white-bg.png';

const authStore = useAuthStore();
const { loginError, isAuthenticated } = storeToRefs(authStore);
const router = useRouter();

const email = ref("");
const password = ref("");
const loginButton = ref(false);
const loginErrorMessage = computed(() => loginError.value);

const envName = process.env.VUE_APP_ENV_NAME;
const envVersion = process.env.VUE_APP_ENV_VERSION;

onMounted(() => {
  if (isAuthenticated.value) {
    router.push({ name: 'HomePage' });
  }
});

const login = async () => {
  loginButton.value = true;
  const response = await authStore.login({
    email: email.value,
    password: password.value,
  });

  if (response && response.success) {
    router.push({ name: 'HomePage' });
  } else {
    console.error('Login error:', loginErrorMessage.value);
  }
  loginButton.value = false;
};
</script>