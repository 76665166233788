// composables/api/useAttendanceSingleUpdate.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import axios from 'axios';

export function useAttendanceSingleUpdate() {
  const isSubmittingRoster = ref(false);

  const submitBulkUpdate = async (rosterPayload, inPayload, outPayload) => {
    isSubmittingRoster.value = true;

    try {
      const requests = [];

      if (rosterPayload.rows.length > 0) {
        requests.push(axiosClient.post(API_ENDPOINTS.bulkEditRoster, rosterPayload, {
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        }));
      }

      if (inPayload.rows.length > 0) {
        requests.push(axiosClient.post(API_ENDPOINTS.editAttendance, inPayload, {
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        }));
      }

      if (outPayload.rows.length > 0) {
        requests.push(axiosClient.post(API_ENDPOINTS.editAttendance, outPayload, {
          headers: { 'Content-Type': 'application/json' },
          timeout: 10000
        }));
      }

      const responses = await Promise.all(requests);

      // Check if all responses are successful and collect messages
      const successMessages = [];
      let allSuccessful = true;
      responses.forEach(response => {
        if (response.data.code === 'SUCCESS') {
          successMessages.push(response.data.message);
        } else {
          allSuccessful = false;
        }
      });

      if (allSuccessful) {
        return { 
          success: true, 
          messages: successMessages,
          severity: 'success',
          summary: 'Success'
        };
      } else {
        throw new Error('One or more requests failed');
      }

    } catch (error) {
      console.error('Error updating attendance:', error);
      let errorMessage = 'Failed to update attendance';
      let severity = 'error';
      let summary = 'Error';

      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          errorMessage = error.response.data.error || `Server error: ${error.response.status}`;
          
          // Adjust severity based on error code
          switch (error.response.data.code) {
            case 'VALIDATION_ERROR':
            case 'INVALID_FORMAT':
              severity = 'warn';
              summary = 'Warning';
              break;
            case 'RECORD_NOT_FOUND':
              severity = 'info';
              summary = 'Information';
              break;
            case 'INTEGRITY_ERROR':
              severity = 'warn';
              summary = 'Warning';
              errorMessage = 'A data conflict occurred. Please refresh and try again.';
              break;
          }
        } else if (error.request) {
          console.error('Error request:', error.request);
          errorMessage = 'No response from server';
        } else {
          console.error('Error message:', error.message);
          errorMessage = error.message;
        }
      } else {
        // Handle non-Axios errors
        errorMessage = error.message || 'An unexpected error occurred';
      }

      return { 
        success: false, 
        error: errorMessage,
        severity,
        summary
      };
    } finally {
      isSubmittingRoster.value = false;
    }
  };

  return {
    isSubmittingRoster,
    submitBulkUpdate
  };
}