// composables/api/useLogs.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useLogs() {
  const allLogs = ref([]);
  const allLogsLoading = ref(false);
  const allLogsError = ref(null);

  const handleError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      return err.response.data.message;
    }
    return 'An unexpected error occurred. Please try again later.';
  };

  const fetchAllLogs = async (employeeId) => {
    allLogsLoading.value = true;
    allLogsError.value = null;

    try {
      const url = API_ENDPOINTS.fetchLogs
        .replace('{employeeId}', employeeId);

      const response = await axiosClient.get(url);
      
      if (Array.isArray(response.data)) {
        allLogs.value = response.data;
      } else {
        console.warn('Received non-array data from API:', response.data);
        allLogs.value = [];
      }
    } catch (err) {
      allLogsError.value = handleError(err);
      allLogs.value = [];
    } finally {
      allLogsLoading.value = false;
    }
  };

  return {
    allLogs,
    allLogsLoading,
    allLogsError,
    fetchAllLogs
  };
}