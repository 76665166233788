// src/apiConfig.js
const API_BASE_URL = process.env.VUE_APP_API_URL;

export const API_ENDPOINTS = {
    // Company APIs
    fetchAllCompanies: `${API_BASE_URL}/view-company/company-data-short/{companyId}/{archiveStatus}`,
    fetchCompanyById: `${API_BASE_URL}/view-company/company/{companyId}`,
    createNewCompany: `${API_BASE_URL}/create-company/insert`,

    // Employee APIs
    fetchAllEmployees: `${API_BASE_URL}/view-employees/employees-data-short/{employeeId}/{companyId}/{archiveStatus}`,
    fetchEmployeeById: `${API_BASE_URL}/view-employees/employee/{employeeId}`,
    createNewEmployee: `${API_BASE_URL}/create-employee/insert`,

    // Rosters APIs
    fetchRosters: `${API_BASE_URL}/view-roster/roster-data/{employeeId}/{companyId}/{targetDate}`,
    fetchRostersBulkIds: `${API_BASE_URL}/view-roster/bulk-view-roster/{rosterIds}`,
    fetchRostersSingle: `${API_BASE_URL}/view-roster/view-last-roster/{employeeId}/{shiftType}`,
    deleteRoster: `${API_BASE_URL}/update-roster/delete-roster`,
    bulkDeleteRoster: `${API_BASE_URL}/update-roster/bulk-delete-roster`,
    bulkEditRoster: `${API_BASE_URL}/update-roster/bulk-update-roster`,
    createRoster: `${API_BASE_URL}/create-roster/insert`,
    createSingleRoster: `${API_BASE_URL}/create-roster/insert/single`,
    fetchSchedule: `${API_BASE_URL}/view-schedule/schedule-data/{companyId}/{startDate}/{endDate}`,

    // Attendance APIs
    fetchAttendance: `${API_BASE_URL}/view-attendance/attendance-data/{employeeId}/{companyId}/{targetDate}`,
    fetchAttendanceSingle: `${API_BASE_URL}/view-attendance/attendance_single_entry/{employeeId}/{rosterId}/{attendanceId}`,
    editAttendance: `${API_BASE_URL}/update-attendance/edit-attendance`,
    overtimeStaus: `${API_BASE_URL}/update-attendance/update-over-below-status`,
    deleteAttendance: `${API_BASE_URL}/update-attendance/delete-attendance`,
    clockIn: `${API_BASE_URL}/mark/kiosk/clockin`,
    clockOut: `${API_BASE_URL}/mark/kiosk/clockout`,

    // Dashboard
    fetchUpcomingRoster: `${API_BASE_URL}/view-roster/dashboard-roster`,
    fetchUpcomingLeaves: `${API_BASE_URL}/view-dashboard/upcoming-leaves/{startDate}/{endDate}`,
    fetchUpcomingEmployees: `${API_BASE_URL}/view-roster/dashboard-attendance/{startDate}/{endDate}`,
    fetchNotlogoutEmployees: `${API_BASE_URL}/view-roster/dashboard-not-logout`,
    fetchOnlineEmployees: `${API_BASE_URL}/view-attendance/online-status`,

    // Logs API
    fetchLogsById: `${API_BASE_URL}/view-logs/logs-by-id/{tableName}/{recordId}/{type}`,
    fetchLogs: `${API_BASE_URL}/view-logs/logs-data/{employeeId}`,
    createLog: `${API_BASE_URL}/create-log/insert`
}