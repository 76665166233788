import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import FocusTrap from 'primevue/focustrap';
import Badge from 'primevue/badge';
import ConfirmationService from 'primevue/confirmationservice';

import { useEmployees } from '@/composables/api/useEmployees';
import { useAuthStore } from '@/stores/auth';

import 'primevue/resources/themes/lara-dark-blue/theme.css';
import 'primeicons/primeicons.css';
import "@/assets/css/WavingFlagAnimation.css";
import 'primeflex/primeflex.css';
import '@/assets/css/custom.css'

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(PrimeVue);
app.use(ToastService);
app.use(ConfirmationService);
app.directive('focustrap', FocusTrap);
app.directive('badge', Badge);
app.component('PrimeToast', Toast);

const authStore = useAuthStore();

// Initialize auth before setting up router
await authStore.initializeAuth();

app.use(router);

// Setup navigation guard
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    next('/login');
  } else if (to.path === '/login' && authStore.isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

// Error handling for resource loading
app.config.errorHandler = (err, vm, info) => {
  console.error('Vue Error:', err, info);
};

window.addEventListener('error', (event) => {
  if (event.target && (event.target.tagName === 'LINK' || event.target.tagName === 'SCRIPT')) {
    console.error('Resource loading error:', event.target.src || event.target.href);
  }
});

// Fetch employees if authenticated
if (authStore.isAuthenticated) {
  const { fetchEmployees } = useEmployees();
  fetchEmployees();
}

// Mount the app
app.mount('#app');