// composables/useUserSelections.js
import { computed, watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserSelectionStore } from '@/stores/userSelectionStore';
import { useEmployees } from '@/composables/api/useEmployees';

export function useUserSelections() {
  const { employeesList, companiesList, employeesListLoading, fetchEmployees } = useEmployees();
  const userSelectionStore = useUserSelectionStore();
  const { store_employee_company_id, store_employee_id } = storeToRefs(userSelectionStore);

  const isUpdating = ref(false);

  const selectedCompany = computed({
    get: () => {
      if (store_employee_company_id.value) {
        return companiesList.value.find(company => company.company_id === store_employee_company_id.value) || null;
      }
      return null;
    },
    set: (value) => {
      if (!isUpdating.value) {
        isUpdating.value = true;
        userSelectionStore.setCompanyId(value ? value.company_id : null);
        userSelectionStore.setEmployeeId(null);
        isUpdating.value = false;
      }
    }
  });

  const selectedEmployee = computed({
    get: () => {
      if (store_employee_id.value) {
        return employeesList.value.find(employee => employee.employee_id === store_employee_id.value) || null;
      }
      return null;
    },
    set: (value) => {
      if (!isUpdating.value) {
        isUpdating.value = true;
        userSelectionStore.setEmployeeId(value ? value.employee_id : null);
        if (value) {
          userSelectionStore.setCompanyId(value.employee_company_id);
        }
        isUpdating.value = false;
      }
    }
  });

  const filteredEmployees = computed(() => {
    if (!employeesList.value || !Array.isArray(employeesList.value)) {
      console.warn('employeesList.value is not an array:', employeesList.value);
      return [];
    }

    return employeesList.value.filter(employee => {
      const companyMatch = !selectedCompany.value || employee.employee_company_id === selectedCompany.value.company_id;
      return companyMatch;
    });
  });

  const onCompanyClear = () => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      selectedCompany.value = null;
      selectedEmployee.value = null;
      isUpdating.value = false;
    }
  };

  const onEmployeeClear = () => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      selectedEmployee.value = null;
      isUpdating.value = false;
    }
  };

  const onCompanyChange = (event) => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      selectedCompany.value = event.value;
      selectedEmployee.value = null;
      isUpdating.value = false;
    }
  };

  const onEmployeeSelect = (event) => {
    if (!isUpdating.value) {
      isUpdating.value = true;
      let selectedValue = event.value || event.data;
      selectedEmployee.value = selectedValue;
      isUpdating.value = false;
    }
  };

  // Single watcher for both employee and company changes
  watch([store_employee_id, store_employee_company_id], ([newEmployeeId, newCompanyId], [oldEmployeeId, oldCompanyId]) => {
    if (newEmployeeId !== oldEmployeeId || newCompanyId !== oldCompanyId) {
      // Trigger any necessary updates or API calls here
      console.log('Employee or Company selection changed');
    }
  });

  return {
    selectedCompany,
    selectedEmployee,
    employeesListLoading,
    filteredEmployees,
    companiesList,
    onEmployeeClear,
    onCompanyClear,
    onCompanyChange,
    onEmployeeSelect,
    fetchEmployees
  };
}