// composables/api/useAttendanceSingleApprove.js
import { ref } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';
import axios from 'axios';

export function useAttendanceSingleApprove() {
  const isApproving = ref(false);

  const updateOverBelowStatus = async (payload) => {
    isApproving.value = true;

    try {
      const response = await axiosClient.post(API_ENDPOINTS.overtimeStaus, payload, {
        headers: { 'Content-Type': 'application/json' },
        timeout: 10000
      });

      if (response.data.code === 'SUCCESS') {
        return { 
          success: true, 
          message: response.data.message,
          severity: 'success',
          summary: 'Success'
        };
      } else {
        throw new Error(response.data.message || 'Failed to update overtime status');
      }

    } catch (error) {
      console.error('Error updating overtime status:', error);
      let errorMessage = 'Failed to update overtime status';
      let severity = 'error';
      let summary = 'Error';

      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Error response:', error.response.data);
          console.error('Error status:', error.response.status);
          errorMessage = error.response.data.error || `Server error: ${error.response.status}`;
          
          // Adjust severity based on error code
          switch (error.response.data.code) {
            case 'VALIDATION_ERROR':
            case 'INVALID_FORMAT':
              severity = 'warn';
              summary = 'Warning';
              break;
            case 'RECORD_NOT_FOUND':
              severity = 'info';
              summary = 'Information';
              break;
            case 'INTEGRITY_ERROR':
              severity = 'warn';
              summary = 'Warning';
              errorMessage = 'A data conflict occurred. Please refresh and try again.';
              break;
          }
        } else if (error.request) {
          console.error('Error request:', error.request);
          errorMessage = 'No response from server';
        } else {
          console.error('Error message:', error.message);
          errorMessage = error.message;
        }
      } else {
        // Handle non-Axios errors
        errorMessage = error.message || 'An unexpected error occurred';
      }

      return { 
        success: false, 
        error: errorMessage,
        severity,
        summary
      };
    } finally {
      isApproving.value = false;
    }
  };

  return {
    isApproving,
    updateOverBelowStatus
  };
}