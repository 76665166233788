// axiosClient.js
import axios from 'axios';
import { auth } from '@/firebaseConfig';

const axiosClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

let isRefreshing = false;
let refreshSubscribers = [];

const getCurrentUserIdToken = async () => {
    if (!auth.currentUser) {
        await new Promise((resolve) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                unsubscribe();
                resolve(user);
            });
        });
    }

    if (auth.currentUser) {
        try {
            return auth.currentUser.getIdToken(true);
        } catch (error) {
            console.error('Error getting ID token:', error);
            return null;
        }
    }

    return null;
};

const processQueue = (error, token = null) => {
    refreshSubscribers.forEach(cb => cb(error, token));
    refreshSubscribers = [];
};

const retryRequest = async (error, retryCount = 0) => {
    const maxRetries = 3;
    const initialDelay = 1000; // 1 second

    if (retryCount < maxRetries) {
        const delay = initialDelay * Math.pow(2, retryCount);
        await new Promise(resolve => setTimeout(resolve, delay));
        
        try {
            const newToken = await getCurrentUserIdToken();
            if (newToken) {
                error.config.headers.Authorization = `Bearer ${newToken}`;
                return axiosClient(error.config);
            }
        } catch (retryError) {
            console.error('Error during retry:', retryError);
        }
        
        // If we're here, either newToken was null or there was an error
        // Retry again with increased count
        return retryRequest(error, retryCount + 1);
    }

    // If we've exhausted all retries, reject the promise
    return Promise.reject(error);
};

axiosClient.interceptors.request.use(async (config) => {
    try {
        const token = await getCurrentUserIdToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    } catch (error) {
        console.error('Error in request interceptor:', error);
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosClient.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                refreshSubscribers.push((error, token) => {
                    if (error) {
                        reject(error);
                    } else {
                        originalRequest.headers.Authorization = `Bearer ${token}`;
                        resolve(axiosClient(originalRequest));
                    }
                });
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
            // Use retryRequest here
            const result = await retryRequest(error);
            isRefreshing = false;
            processQueue(null, result.config.headers.Authorization.split(' ')[1]);
            return result;
        } catch (refreshError) {
            isRefreshing = false;
            processQueue(refreshError, null);
            console.error('Error refreshing token:', refreshError);
            // Handle the error (e.g., redirect to login)
            return Promise.reject(refreshError);
        }
    }

    // For non-401 errors or if retries are exhausted
    console.error('Axios error:', error.response ? error.response.data : error.message);
    return Promise.reject(error);
});

export default axiosClient;