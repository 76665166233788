// composables/useRosterBulk.js
import { ref, readonly } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useRosterBulk() {
  const bulkRosters = ref([]);
  const loading = ref(false);
  const error = ref(null);

  const fetchBulkRosters = async (rosterIds) => {
    if (!rosterIds || rosterIds.length === 0) {
      error.value = "No roster IDs provided";
      return;
    }

    loading.value = true;
    error.value = null;

    try {
      const url = API_ENDPOINTS.fetchRostersBulkIds.replace('{rosterIds}', rosterIds.join(','));
      const response = await axiosClient.get(url);
      
      if (response.data && Array.isArray(response.data)) {
        bulkRosters.value = response.data;
      } else {
        throw new Error("Invalid response format");
      }
    } catch (err) {
      console.error("Error fetching bulk rosters:", err);
      error.value = handleError(err);
    } finally {
      loading.value = false;
    }
  };

  const handleError = (err) => {
    if (err.response && err.response.data && err.response.data.message) {
      return err.response.data.message;
    }
    return 'An error occurred while fetching roster data. Please try again.';
  };

  return {
    bulkRosters: readonly(bulkRosters),
    loading: readonly(loading),
    error: readonly(error),
    fetchBulkRosters
  };
}