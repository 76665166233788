<template>
    <div class="flex mb-2">
        <div class="flex-0 flex align-items-center justify-content-center">
          <div class="px-0 py-2">
            <h2 class="m-0 p-0 font-light text-600">Logs</h2>
            <p class="p-0 m-0 pt-1 text-xs text-300"> Showing {{ filteredLogs.length }} results.</p>
          </div>
        </div>
        <div class="flex-1 flex align-items-center gap-2 justify-content-end">
          <div style="position: absolute; right: 30px; bottom: 30px; z-index: 1;" v-if="selectedRows.length > 0" class="shadow-8 m-3">
            <Button v-if="selectedSingleRosterId" @click="viewLogs=true" label="View Logs" class="border-0 text-lg surface-300 hover:surface-500 mr-2 w-full mb-2" />
            <Button @click="openDialog" label="Edit Roster" class="px-4 py-3 text-lg w-full block shadow-3 surface-300 hover:surface-500 w-full" />
          </div>
          <Dropdown v-if="!isDropdownDisabled" v-model="selectedEmployee" 
            :options="filteredEmployees" :loading="allLogsLoading" :disabled="isRefreshing"
            filter showClear optionLabel="employee_name" placeholder="All Employees" class="w-full md:w-16rem" @change="onEmployeeSelect">
            <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                    <div>{{ slotProps.value.employee_name }}</div>
                </div>
                <span v-else class="text-sm text-300">
                    {{ slotProps.placeholder }}
                </span>
            </template>
            <template #option="slotProps">
                <div class="flex align-items-center">
                    <div>{{ slotProps.option.employee_name }}</div>
                </div>
            </template>
          </Dropdown>
          <Button icon="pi pi-refresh" :loading="isRefreshing" @click="refreshData" class="surface-300 hover:surface-500" />
      </div>
    </div>  
    <div v-if="isRefreshing">
      <div class="imab-logo-animated">
        <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
      </div>
    </div>
    <div v-if="filteredLogs.length">
        <DataTable :value="allLogs" aria-label="Roster Table" :loading="allLogsLoading">
            <Column field="user_email" header="User" class="text-600 w-2">
                <template #body="slotProps">
                    <div class="flex items-center gap-2 pt-2">
                        <div>
                            <p class="block font-bold pb-1 m-0 p-0">
                            {{ slotProps.data.user_email }}
                            </p>
                            <p class="block text-xs text-300 m-0 p-0 pb-1">{{ dateFromIsoString(slotProps.data.timestamp) }}</p>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="employee_name" header="Employee Name" class="text-600 w-2">
                <template #body="slotProps">
                    <div class="flex items-center gap-2 pt-2">
                        <div>
                            <p class="block font-bold pb-1 m-0 p-0">
                            {{ slotProps.data.employee_name }}
                            </p>
                            <p class="block text-xs text-300 m-0 p-0 pb-1">{{ slotProps.data.employee_company_name }}</p>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="type" header="Type" class="text-600 w-2">
            <template #body="slotProps">
                <div class="flex items-center gap-2 pt-2">
                <div>
                    <p class="block font-bold pb-1 m-0 p-0">
                        {{ slotProps.data.action }}
                    </p>
                    <p class="block text-xs text-300 m-0 p-0 pb-1">{{ slotProps.data.type }}</p>
                </div>
                </div>
            </template>
            </Column>
            <Column field="action" header="Section" class="text-600 w-2">
            <template #body="slotProps">
                <div class="flex items-center gap-2 pt-2">
                <div>
                    <p class="block font-bold pb-1 m-0 p-0">
                        {{ slotProps.data.table_name }}
                    </p>
                    <p class="block text-xs text-300 m-0 p-0 pb-1">ID #{{ slotProps.data.record_id }}</p>
                </div>
                </div>
            </template>
            </Column>
            <Column field="action" header="Action" class="text-600 w-1">
            <template #body="slotProps">
                <p class="block font-bold text-400 pb-1 m-0 p-0">
                  {{ slotProps.data.priority }}
                </p>
            </template>
            </Column>
            <Column field="changes" header="Changes" class="text-600 w-full">
                <template #body="slotProps">
                    <template v-if="slotProps.data.type === 'MANUAL'">
                        <div class="text-blue-500">
                            Manual Entry: {{ getManualEntry(slotProps.data.changes) }}
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'UPDATE'">
                        <div v-if="hasChanges(slotProps.data.changes)">
                            <div v-for="(value, key) in getChangedValues(slotProps.data.changes)" :key="key" class="mb-2 w-full">
                                <div class="block flex mb-1 w-full">
                                    <span class="line-through text-red-200 w-11rem mr-2">{{ key }}:</span> 
                                    <span class="line-through w-full text-red-200">{{ formatValue(key, slotProps.data.changes.old[key]) }}</span>
                                </div>
                                <div class="block flex w-full">
                                    <span class="mr-2 text-green-600 w-11rem">{{ key }}:</span>
                                    <span class="w-full text-green-600">{{ formatValue(key, value) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!isManualEntry(slotProps.data.changes)" class="text-gray-500 font-italic">
                            No Change
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'INSERT'">
                        <div v-for="key in getRelevantFields(slotProps.data.table_name)" :key="key" class="mb-2 w-full">
                            <div v-if="slotProps.data.changes && slotProps.data.changes.hasOwnProperty(key)" class="block flex mb-1 w-full">
                                <span class="text-500 w-11rem mr-2">{{ key }}:</span> 
                                <span class="w-full text-500">{{ formatValue(key, slotProps.data.changes[key]) }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'DELETE'">
                        <div v-for="key in getRelevantFields(slotProps.data.table_name)" :key="key" class="mb-2 w-full">
                            <div v-if="slotProps.data.changes && slotProps.data.changes.hasOwnProperty(key)" class="block flex mb-1 w-full">
                                <span class="line-through text-red-500 w-11rem mr-2">{{ key }}:</span> 
                                <span class="line-through w-full text-red-500">{{ formatValue(key, slotProps.data.changes[key]) }}</span>
                            </div>
                        </div>
                    </template>
                </template>
            </Column>
        </DataTable>
    </div>
    <div v-else>
      No Logs found for this filter. Please refresh to see latest logs.
    </div>
    <Dialog v-model:visible="viewLogs" :style="{ width: '60vw' }" :modal="true">
      <template #header>
        <h2>Logs</h2>
      </template>
      <LogsDialog :selectedRosterIds="selectedSingleRosterId" logType="roster" @handleBack="handleBack" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, computed, watch, defineProps } from 'vue';
import { useLogs } from "@/composables/api/useLogs";
import { useUtils } from '@/composables/useUtils';
import { useToast } from 'primevue/usetoast';
import { useUserSelections } from '@/composables/useUserSelections';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import LogsDialog from '@/components/dialogs/LogsDialog.vue';

const props = defineProps({
  targetEmployeeId: {
    type: Number,
    required: false
  },
  targetRows: {
    type: Number,
    required: false
  }
});

const { allLogs, allLogsLoading, allLogsError, fetchAllLogs } = useLogs();
const { dateFromIsoString } = useUtils();
const { 
  selectedEmployee, 
  filteredEmployees, 
  fetchEmployees 
} = useUserSelections();

const toast = useToast();
const onEmployeeSelect = async (event) => {
  if (event.value) {
    await refreshData();
  } else {
    // Handle clear selection
    selectedEmployee.value = null;
    await refreshData();
  }
};
const editRosterDialog = ref(false);
const createRosterDialog = ref(false);
const viewLogs = ref(false);

const isDropdownDisabled = computed(() => !!props.targetEmployeeId);

// Local loading state for refresh operation
const isRefreshing = ref(false);


const isManualEntry = (changes) => {
  return changes && changes.manual_entry !== undefined;
};

const getManualEntry = (changes) => {
  if (typeof changes === 'string') {
    try {
      changes = JSON.parse(changes);
    } catch (e) {
      console.error('Error parsing changes JSON:', e);
      return 'Error: Invalid manual entry format';
    }
  }
  return changes.manual_entry || 'No manual entry text';
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
};

const formatValue = (key, value) => {
  const dateFields = ['attendance_in_out', 'roster_start', 'roster_end'];
  if (dateFields.includes(key)) {
    return formatDate(value);
  }
  return value;
};

const hasChanges = (changes) => {
  if (isManualEntry(changes)) return true;
  return changes && changes.new && changes.old && 
         Object.keys(changes.new).some(key => changes.new[key] !== changes.old[key]);
};

const getChangedValues = (changes) => {
  if (isManualEntry(changes)) {
    return { manual_entry: changes.manual_entry };
  }
  return changes && changes.new && changes.old
    ? Object.keys(changes.new).reduce((acc, key) => {
        if (changes.new[key] !== changes.old[key]) {
          acc[key] = changes.new[key];
        }
        return acc;
      }, {})
    : {};
};
const getRelevantFields = (tableName) => {
  switch(tableName) {
    case 'attendance':
      return ['attendance_in_out', 'attendance_status'];
    case 'roster':
      return ['roster_start', 'roster_end'];
    default:
      console.warn(`Unknown table name: ${tableName}`);
      return ['id']; // Return a safe default field
  }
};

const selectedRows = ref([]);
const first = ref(0);
const rows = ref(props.targetRows || 100);

// Error handling function
const handleError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  let errorMessage = 'An unexpected error occurred.';
  if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === 'string') {
    errorMessage = error;
  }
  toast.add({
    severity: 'error',
    summary: `Error: ${context}`,
    detail: errorMessage,
    life: 12000
  });
};

// Filtered logs based on dropdown selections
const filteredLogs = computed(() => {
  if (!Array.isArray(allLogs.value)) {
    console.warn('allLogs.value is not an array:', allLogs.value);
    return [];
  }
  return allLogs.value;
});

// Update refreshData function
const refreshData = async () => {
  isRefreshing.value = true;
  try {
    const idToFetch = props.targetEmployeeId || (selectedEmployee.value ? selectedEmployee.value.employee_id : 0);
    selectedRows.value = [];
    await fetchAllLogs(idToFetch, 0);
    // Add warning toast if needed
  } catch (err) {
    handleError(err, 'Fetching Logs');
  } finally {
    isRefreshing.value = false;
  }
};

// Emits
const handleBack = () => {
  editRosterDialog.value = false;
  createRosterDialog.value = false;
  selectedRows.value = [];
};

// Watch for changes in selectedEmployee
watch(selectedEmployee, async (newValue) => {
  if (!props.targetEmployeeId && newValue) {
    await refreshData();
  }
});

// New watch for targetEmployeeId prop
watch(() => props.targetEmployeeId, async (newValue) => {
  if (newValue) {
    const targetEmployee = filteredEmployees.value.find(emp => emp.employee_id === newValue);
    if (targetEmployee) {
      selectedEmployee.value = targetEmployee;
      await refreshData();
    } 
  }
}, { immediate: true });

// Watch for changes in dropdown selections
watch([selectedEmployee], () => {
  first.value = 0; 
});

// Update error watch
watch(allLogsError, (newLogsError) => {
  if (newLogsError) {
    handleError(newLogsError, 'Logs');
  }
});

// Watch for changes in props.targetRows
watch(() => props.targetRows, (newValue) => {
  if (newValue) {
    rows.value = newValue;
  }
});

// Update lifecycle hooks
onMounted(async () => {
  try {
    if (filteredEmployees.value.length === 0) {
      await fetchEmployees();
    }
    if (!props.targetEmployeeId) {
      await refreshData();
    }
  } catch (error) {
    handleError(error, 'Component Initialization');
  }
});
</script>