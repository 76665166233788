<template>
  <div v-if="authStore.isInitialized">
    <component :is="currentLayout">
      <router-view v-if="authStore.isInitialized"/>
      <div v-else>Loading...</div>
    </component>
  </div>
  <div v-else>Initializing...</div>
</template>

<script setup>
import { computed, ref, onMounted, nextTick, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import MainLayout from './views/MainLayout.vue';

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

const layoutComponent = ref('div');

// Use computed property for layout
const currentLayout = computed(() => {
  return route.meta.layout === 'none' ? layoutComponent.value : MainLayout;
});

onMounted(async () => {
  await nextTick();
  layoutComponent.value = 'div';
});

// Handle authentication state changes
const handleAuthStateChange = async () => {
  if (authStore.isInitialized) {
    if (authStore.isAuthenticated && route.path === '/login') {
      await router.push('/');
    } else if (!authStore.isAuthenticated && route.meta.requiresAuth) {
      await router.push('/login');
    }
  }
};

// Watch for changes in authentication state
watch(() => authStore.isInitialized, handleAuthStateChange, { immediate: true });
watch(() => authStore.isAuthenticated, handleAuthStateChange);
</script>