<template>
  <div class="homepage">
    <div class="flex mb-0 surface-50 px-3 mb-2 border-round-sm">
      <div class="flex-0 flex align-items-center justify-content-center">
        <div class="px-0 py-2">
          <h1 class="m-0 p-0 text-3xl font-light text-600">Today</h1>
          <p class="p-0 m-0 pt-1 text-base text-400"> {{ getTodayDate() }}</p>
        </div>
      </div>
      <div class="flex-1 flex align-items-center justify-content-end">
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-4">
          <strong class="uppercase text-200 ml-1 pt-1 font-normal">Auto-Refresh</strong>
          <Badge class="surface-100 text-300 w-3rem py-0 mt-0">{{ formattedCountdown }}</Badge> 
        </div>
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3 text-sm">
          <Badge class="surface-300 px-1 py-0 mt-0">{{ todayManagers.length }}</Badge> 
          <strong class="uppercase text-400 pt-1 font-normal">Managers</strong>
        </div>
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3 text-sm">
          <Badge class="surface-300 px-1 py-0 mt-0">{{ todayITStaff.length }}</Badge> 
          <strong class="uppercase text-400 pt-1 font-normal">Tech</strong>
        </div>
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3 text-sm">
          <Badge class="surface-300 px-1 py-0 mt-0">{{ todayCoveringStaff.length }}</Badge> 
          <strong class="uppercase text-400 pt-1 font-normal">COVER</strong>
        </div>
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3 text-sm">
          <Badge class="surface-300 px-1 py-0 mt-0">{{ todayMaintenanceStaff.length }}</Badge> 
          <strong class="uppercase text-400 pt-1 font-normal">Maintenace</strong>
        </div>
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3 text-sm">
          <Badge class="surface-300 px-1 py-0 mt-0">{{ todayHouseKeeping.length }}</Badge> 
          <strong class="uppercase text-400 pt-1 font-normal">Keep</strong>
        </div>
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3 text-sm">
          <Badge class="surface-300 px-1 py-0 mt-0">{{ todaySecurityStaff.length }}</Badge> 
          <strong class="uppercase text-400 pt-1 font-normal">Guards</strong>
        </div>
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3">
          <Badge class="bg-green-400 px-3 py-0 mt-0">{{ notlogoutAttendance.length }}</Badge> 
          <strong class="uppercase text-600 ml-1 pt-1 font-normal">Online</strong>
        </div>
        <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3">
          <Badge class="surface-300 px-3 py-0 mt-0">{{ todayWorked.length }}</Badge> 
          <strong class="uppercase text-600 ml-1 pt-1 font-normal">Worked</strong>
        </div>
        <Button icon="pi pi-refresh" :loading="dashboardDataLoading || attendanceLoadingIndicator" @click="handleUpdateSuccess" class="surface-300 hover:surface-500 mr-2" />
      </div>
    </div> 
    <div v-if="dashboardDataLoading">
      <div class="imab-logo-animated">
        <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
      </div>
    </div>
    <div v-else>
      <div class="dashboard-sections flex flex-wrap justify-content-start gap-0">
        <section-status 
          title="UPCOMING" 
          :records="todayRoster" 
          :sectionClass="'upcoming w-2'"
          :showCounter="true"
          @updateSuccess="handleUpdateSuccess"
          :loadingIndicator="attendanceLoadingIndicator">
        </section-status>
        <section-status 
          title="LEAVES" 
          :records="todayLeaves" 
          :sectionClass="'leaves w-2'"
          :showCounter="false"
          @updateSuccess="handleUpdateSuccess"
          :loadingIndicator="attendanceLoadingIndicator">
        </section-status>
        <section-status 
          title="LATE" 
          :records="lateRecords" 
          :sectionClass="'late w-2'"
          :showCounter="true"
          @updateSuccess="handleUpdateSuccess"
          :loadingIndicator="attendanceLoadingIndicator">
        </section-status>
        <section-status 
          title="EARLY" 
          :records="earlyRecords" 
          :sectionClass="'early w-2'"
          :showCounter="true"
          @updateSuccess="handleUpdateSuccess"
          :loadingIndicator="attendanceLoadingIndicator">
        </section-status>
        <section-status 
          title="OVERTIME" 
          :records="overtimeRecords" 
          :sectionClass="'overtime w-2'"
          :showCounter="true"
          @updateSuccess="handleUpdateSuccess"
          :loadingIndicator="attendanceLoadingIndicator">
        </section-status>
        <div class="w-2">
          <section-status 
            title="NOTLOGOUT" 
            :records="notLogout" 
            :sectionClass="'notlogout'"
            :showCounter="true"
            @updateSuccess="handleUpdateSuccess"
            :loadingIndicator="attendanceLoadingIndicator">
          </section-status>
          <section-status style="margin-top: 20px;"
            title="COVERS" 
            :records="coverRecords" 
            :sectionClass="'covers'"
            :showCounter="true"
            @updateSuccess="handleUpdateSuccess"
            :loadingIndicator="attendanceLoadingIndicator">
          </section-status>
        </div>
      </div>
      <div class="yesterday mt-4">
        <div class="flex mb-0 surface-50 px-3 mb-2 border-round-sm">
          <div class="flex-0 flex align-items-center justify-content-center">
            <div class="px-0 py-2">
              <h1 class="m-0 p-0 text-3xl font-light text-600">Yesterday</h1>
              <p class="p-0 m-0 pt-1 text-base text-400"> {{ getYesterdayDate() }}</p>
            </div>
          </div>
          <div class="flex-1 flex align-items-center justify-content-end">
            <div v-if="!dashboardDataLoading || attendanceLoadingIndicator" class="flex flex-wrap justify-content-start gap-1 mr-3">
              <Badge class="surface-400 px-3 py-0 mt-0">{{ yesterdayAttendanceRecords.length }}</Badge> 
              <strong class="uppercase text-600 ml-1 pt-1 font-normal" style="letter-spacing: 1px;">Worked</strong>
            </div>
          </div>
        </div>
        <div class="dashboard-sections flex flex-wrap justify-content-start gap-0">
          <section-status 
            title="ONTIME" 
            :records="yesterdayOntimeRecords" 
            :sectionClass="'ontime w-2'"
            :showCounter="true"
            @updateSuccess="handleUpdateSuccess"
            :loadingIndicator="attendanceLoadingIndicator">
          </section-status>
          <section-status 
            title="LEAVES" 
            :records="yesterdayLeaves" 
            :sectionClass="'leaves w-2'"
            :showCounter="true"
            @updateSuccess="handleUpdateSuccess"
            :loadingIndicator="attendanceLoadingIndicator">
          </section-status>
          <section-status 
            title="LATE" 
            :records="yesterdayLateRecords" 
            :sectionClass="'late w-2'"
            :showCounter="true"
            @updateSuccess="handleUpdateSuccess"
            :loadingIndicator="attendanceLoadingIndicator">
          </section-status>
          <section-status 
            title="EARLY" 
            :records="yesterdayEarlyRecords" 
            :sectionClass="'early w-2'"
            :showCounter="true"
            @updateSuccess="handleUpdateSuccess"
            :loadingIndicator="attendanceLoadingIndicator">
          </section-status>
          <section-status 
            title="OVERTIME" 
            :records="yesterdayOvertimeRecords" 
            :sectionClass="'overtime w-2'"
            :showCounter="true"
            @updateSuccess="handleUpdateSuccess"
            :loadingIndicator="attendanceLoadingIndicator">
          </section-status>
          <section-status
            title="COVERS" 
            :records="yesterdayCoverRecords" 
            :sectionClass="'covers w-2'"
            :showCounter="true"
            @updateSuccess="handleUpdateSuccess"
            :loadingIndicator="attendanceLoadingIndicator">
          </section-status>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useFetchUpcomingEmployees } from '@/composables/api/dashboard/useFetchUpcomingEmployees';
import { useFetchNotlogoutEmployees } from '@/composables/api/dashboard/useFetchNotlogoutEmployees';
import { useFetchUpcomingRosters } from '@/composables/api/dashboard/useFetchUpcomingRosters';
import { useLondonUnixTime } from '@/composables/time/useLondonTime';
import Button from 'primevue/button';
import Badge from 'primevue/badge';
import SectionStatus from '@/components/dashboard/SectionStatus.vue';

const {
  getUnixTimeInThreeHours,
  getLondonUnixTime,
  startOfCurrentDay,
  endOfCurrentDay,
  startOfYesterday,
  endOfYesterday,
  getTodayDate,
  getYesterdayDate
} = useLondonUnixTime();

const currentTimeUnix = ref(0);
const startOfCurrentDayUnix = ref(0);
const endOfCurrentDayUnix = ref(0);
const startOfYesterdayUnix = ref(0);
const endOfYesterdayUnix = ref(0);

const attendanceLoadingIndicator = ref(false);
const dashboardDataLoading = ref(false);

const upcomingRosters = ref([]);
const notlogoutAttendance = ref([]);
const leaveRecords = ref([]);
const attendanceRecords = ref([]);
const yesterdayLeaveRecords = ref([]);
const yesterdayAttendanceRecords = ref([]);

// New refs for auto-refresh functionality
const refreshInterval = 3 * 60 * 1000; // 3 minutes in milliseconds
const timeUntilNextRefresh = ref(refreshInterval);
let refreshTimer;
let countdownTimer;

const initDashboardData = () => {
    const cachedDashboardData = sessionStorage.getItem('dashboardData');
    if (cachedDashboardData) {
        const data = JSON.parse(cachedDashboardData);
        notlogoutAttendance.value = data.notlogoutAttendance || [];
        leaveRecords.value = data.leaveRecords || [];
        attendanceRecords.value = data.attendanceRecords || [];
        yesterdayLeaveRecords.value = data.yesterdayLeaveRecords || [];
        yesterdayAttendanceRecords.value = data.yesterdayAttendanceRecords || [];
        upcomingRosters.value = data.upcomingRosters || [];
        dashboardDataLoading.value = false;
    } else {
        dashboardDataLoading.value = true;
    }
};

// API Calls to fetch the filtered dashboard data
const fetchDataFromServer = async () => {
    currentTimeUnix.value = getLondonUnixTime();
    startOfCurrentDayUnix.value = startOfCurrentDay();
    endOfCurrentDayUnix.value = endOfCurrentDay();
    startOfYesterdayUnix.value = startOfYesterday();
    endOfYesterdayUnix.value = endOfYesterday();
    attendanceLoadingIndicator.value = true;

    const {
        data: upcomingEmployeesData,
        fetchUpcomingEmployees
    } = useFetchUpcomingEmployees(startOfYesterdayUnix.value, endOfCurrentDayUnix.value);

    const {
        data: notlogoutEmployeesData,
        fetchNotlogoutEmployees
    } = useFetchNotlogoutEmployees();

    const {
        data: upcomingRostersData,
        fetchUpcomingRosters
    } = useFetchUpcomingRosters();

    try {
        await Promise.all([
            fetchUpcomingEmployees(),
            fetchNotlogoutEmployees(),
            fetchUpcomingRosters()
        ]);

        const filteredRosterRecords = upcomingRostersData.value.filter(roster =>
            roster.event_start > currentTimeUnix.value
        );
        const filteredLeaveRecords = upcomingRostersData.value
          .filter(leave =>
              leave.event_start < currentTimeUnix.value && leave.event_start > startOfCurrentDayUnix.value
          )
          .sort((a, b) => {
              // First, prioritize 'LEAVE' event_type
              if (a.event_type === 'LEAVE' && b.event_type !== 'LEAVE') return -1;
              if (b.event_type === 'LEAVE' && a.event_type !== 'LEAVE') return 1;
              
              // If both are 'LEAVE' or both are not 'LEAVE', sort by event_start in descending order
              return b.event_start - a.event_start;
          });
        const filteredAttendanceRecords = upcomingEmployeesData.value.filter(record =>
            record.attendance_status_out === null || record.event_start >= startOfCurrentDayUnix.value && record.event_start < endOfCurrentDayUnix.value
        );
        const filteredYesterdayLeaveRecords = upcomingRostersData.value
          .filter(leave =>
            leave.event_start >= startOfYesterdayUnix.value && leave.event_start < endOfYesterdayUnix.value
          )
          .sort((a, b) => {
              // First, prioritize 'LEAVE' event_type
              if (a.event_type === 'LEAVE' && b.event_type !== 'LEAVE') return -1;
              if (b.event_type === 'LEAVE' && a.event_type !== 'LEAVE') return 1;
              
              // If both are 'LEAVE' or both are not 'LEAVE', sort by event_start in descending order
              return b.event_start - a.event_start;
          });
        const filteredYesterdayAttendanceRecords = upcomingEmployeesData.value.filter(record =>
          record.attendance_status_out !== null && record.event_start >= startOfYesterdayUnix.value && record.event_start < endOfYesterdayUnix.value
        );

        sessionStorage.setItem('dashboardData', JSON.stringify({
            notlogoutAttendance: notlogoutEmployeesData.value || [],
            leaveRecords: filteredLeaveRecords,
            attendanceRecords: filteredAttendanceRecords,
            yesterdayLeaveRecords: filteredYesterdayLeaveRecords,
            yesterdayAttendanceRecords: filteredYesterdayAttendanceRecords,
            upcomingRosters: filteredRosterRecords || []
        }));

        // Update reactive states
        notlogoutAttendance.value = notlogoutEmployeesData.value;
        leaveRecords.value = filteredLeaveRecords;
        attendanceRecords.value = filteredAttendanceRecords;
        yesterdayLeaveRecords.value = filteredYesterdayLeaveRecords;
        yesterdayAttendanceRecords.value = filteredYesterdayAttendanceRecords;
        upcomingRosters.value = filteredRosterRecords;
    } catch (error) {
        console.error("Failed to fetch data from server:", error);
    } finally {
        attendanceLoadingIndicator.value = false;
        dashboardDataLoading.value = false;
    }
};

// Today Counter
const todayWorked = computed(() => attendanceRecords.value?.filter(record => record.attendance_out !== null) || []);
const todayManagers = computed(() => notlogoutAttendance.value?.filter(record => record.employee_department === "Manager") || []);
const todayITStaff = computed(() => notlogoutAttendance.value?.filter(record => record.employee_department === "Tech") || []);
const todayCoveringStaff = computed(() => notlogoutAttendance.value?.filter(record => record.employee_department === "HR / Shift Cover") || []);
const todayHouseKeeping = computed(() => notlogoutAttendance.value?.filter(record => record.employee_department === "House Keeping") || []);
const todayMaintenanceStaff = computed(() => notlogoutAttendance.value?.filter(record => record.employee_department === "Maintenance") || []);
const todaySecurityStaff = computed(() => notlogoutAttendance.value?.filter(record => record.employee_department === "Security") || []);

// TODAY Dashboard
const todayRoster = computed(() => {
  const currentTime = getLondonUnixTime();
  const threeHoursLater = getUnixTimeInThreeHours();
  return upcomingRosters.value?.filter(record => 
    (record.event_type === 'SHIFT' || record.event_type === 'COVER') &&
    record.event_start >= currentTime &&
    record.event_start <= threeHoursLater
  ) || [];
});
const todayLeaves = computed(() => leaveRecords.value || []);
const lateRecords = computed(() => 
  (attendanceRecords.value?.filter(record => 
    record.attendance_status_in === 'LATE'
  ) || [])
  .sort((a, b) => b.attendance_in - a.attendance_in)
);
const earlyRecords = computed(() => 
  (attendanceRecords.value?.filter(record => 
    
    (record.attendance_status_in === 'EARLY' || record.attendance_status_out === 'EARLY')
  ) || [])
  .sort((a, b) => b.attendance_out - a.attendance_out)
);
const overtimeRecords = computed(() => 
  (attendanceRecords.value?.filter(record => 
    record.attendance_status_out === 'OVERTIME'
  ) || [])
  .sort((a, b) => b.attendance_out - a.attendance_out)
);
const coverRecords = computed(() => attendanceRecords.value?.filter(record => record.event_type === 'COVER') || []);
const notLogout = computed(() => 
  (notlogoutAttendance.value?.filter(record => 
    currentTimeUnix.value > record.event_end
  ) || [])
  .sort((a, b) => b.event_end - a.event_end)
);

// YESTERDAY Dashboard
const yesterdayLeaves = computed(() => yesterdayLeaveRecords.value?.filter(record => record.event_type === 'LEAVE' || record.event_type === 'SHIFT') || []);
const yesterdayOntimeRecords = computed(() => yesterdayAttendanceRecords.value?.filter(record => record.event_type !== 'COVER' && record.attendance_status_in === 'ONTIME' && (record.attendance_status_out === 'ONTIME' || record.attendance_status_out === 'OVERTIME')) || []);
const yesterdayLateRecords = computed(() => 
  (yesterdayAttendanceRecords.value?.filter(record => 
    record.attendance_status_in === 'LATE'
  ) || [])
  .sort((a, b) => b.attendance_in - a.attendance_in)
);
const yesterdayEarlyRecords = computed(() => 
  (yesterdayAttendanceRecords.value?.filter(record => 
    
    (record.attendance_status_in === 'EARLY' || record.attendance_status_out === 'EARLY')
  ) || [])
  .sort((a, b) => b.attendance_out - a.attendance_out)
);
const yesterdayOvertimeRecords = computed(() => 
  (yesterdayAttendanceRecords.value?.filter(record => 
    record.attendance_status_out === 'OVERTIME'
  ) || [])
  .sort((a, b) => b.attendance_out - a.attendance_out)
);
const yesterdayCoverRecords = computed(() => yesterdayAttendanceRecords.value?.filter(record => record.event_type === 'COVER') || []);

// New computed property for formatted countdown
const formattedCountdown = computed(() => {
  const minutes = Math.floor(timeUntilNextRefresh.value / 60000);
  const seconds = Math.floor((timeUntilNextRefresh.value % 60000) / 1000);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
});

// Modified handleUpdateSuccess function
const handleUpdateSuccess = () => {
  // Clear existing timers
  clearInterval(refreshTimer);
  clearInterval(countdownTimer);

  // Fetch new data
  Promise.all([
    Promise.resolve(initDashboardData()),
    Promise.resolve(fetchDataFromServer())
  ]).then(() => {
    // Reset the countdown and start new timers
    timeUntilNextRefresh.value = refreshInterval;
    startAutoRefresh();
  });
};

// New function to handle automatic refresh
const startAutoRefresh = () => {
  refreshTimer = setInterval(() => {
    handleUpdateSuccess();
  }, refreshInterval);

  // Start the countdown timer
  countdownTimer = setInterval(() => {
    timeUntilNextRefresh.value -= 1000;
    if (timeUntilNextRefresh.value < 0) {
      timeUntilNextRefresh.value = 0;
    }
  }, 1000);
};


onMounted(() => {
  initDashboardData();
  fetchDataFromServer();
  startAutoRefresh(); // Start the auto-refresh cycle
});

onBeforeUnmount(() => {
  clearInterval(refreshTimer);
  clearInterval(countdownTimer);
});

</script>

<style>
.dashboard-sections .nodata{
  color: #555555;
  font-size: 14px;
}
</style>
