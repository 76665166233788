// composables/useEmployees.js
import { ref, onMounted } from 'vue';
import axiosClient from '@/axiosClient';
import { API_ENDPOINTS } from '@/apiConfig';

export function useEmployees() {
  const employeesList = ref([]);
  const companiesList = ref([]);
  const employeesListLoading = ref(false);
  const employeesListError = ref(null);

  function setEmployees(employees) {
    // Sort employees by employee_name in ascending order
    const sortedEmployees = employees.sort((a, b) => 
      a.employee_name.localeCompare(b.employee_name)
    );
    localStorage.setItem('employeesList', JSON.stringify(sortedEmployees));
    employeesList.value = sortedEmployees;
  }
  
  function setCompanies(companies) {
    // Sort companies by company_name in ascending order
    const sortedCompanies = companies.sort((a, b) => 
      a.company_name.localeCompare(b.company_name)
    );
    localStorage.setItem('companiesList', JSON.stringify(sortedCompanies));
    companiesList.value = sortedCompanies;
  }
  
  async function fetchEmployees() {
    const storedEmployees = localStorage.getItem('employeesList');
    const storedCompanies = localStorage.getItem('companiesList');
    
    if (storedEmployees && storedCompanies) {
      employeesList.value = JSON.parse(storedEmployees);
      companiesList.value = JSON.parse(storedCompanies);
      return;
    }

    employeesListLoading.value = true;
    employeesListError.value = null;

    try {
      const url = API_ENDPOINTS.fetchAllEmployees
        .replace('{employeeId}', 0)
        .replace('{companyId}', 0)
        .replace('{archiveStatus}', 0);

      const response = await axiosClient.get(url);
      
      const newEmployeesList = [];
      const newCompaniesList = [];

      response.data.forEach(employee => {
        newEmployeesList.push({
          employee_name: employee.employee_name,
          employee_id: employee.employee_id,
          employee_company_id: employee.company.company_id,
          employee_company_name: employee.company.company_name,
          employee_badge_id: employee.employee_badge_id,
          employee_badge_pin: employee.employee_badge_pin,
          employee_contract: employee.employee_contract,
          employee_department: employee.employee_department,
          employee_email: employee.employee_email,
          employee_join_date: employee.employee_join_date,
          employee_status: employee.employee_status,
          employee_photo_url: employee.employee_photo_url
        });

        if (!newCompaniesList.some(comp => comp.company_id === employee.company.company_id)) {
          newCompaniesList.push(employee.company);
        }
      });

      setEmployees(newEmployeesList);
      setCompanies(newCompaniesList);
    } catch (err) {
      console.error('Error fetching employees:', err);
      employeesListError.value = 'Failed to fetch employees. Please try again later.';
      if (axiosClient.isAxiosError(err)) {
        if (err.response) {
          console.error('Error response:', err.response.data);
          console.error('Error status:', err.response.status);
          employeesListError.value = `Server error: ${err.response.status}`;
        } else if (err.request) {
          console.error('Error request:', err.request);
          employeesListError.value = 'No response from server';
        } else {
          console.error('Error message:', err.message);
          employeesListError.value = err.message;
        }
      }
    } finally {
      employeesListLoading.value = false;
    }
  }

  function initializeFromLocalStorage() {
    const storedEmployees = localStorage.getItem('employeesList');
    const storedCompanies = localStorage.getItem('companiesList');
    
    if (storedEmployees) {
      employeesList.value = JSON.parse(storedEmployees);
    }
    
    if (storedCompanies) {
      companiesList.value = JSON.parse(storedCompanies);
    }
  }

  onMounted(() => {
    initializeFromLocalStorage();
    fetchEmployees();
  });

  return {
    employeesList,
    companiesList,
    employeesListLoading,
    employeesListError,
    fetchEmployees,
    initializeFromLocalStorage
  };
}