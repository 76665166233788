<template>
    <div class="relative">
      <div v-if="logsLoading && reactiveSingleLogs.length === 0" class="h-10rem">
        <div class="imab-logo-animated" style="left: 30%; top: 10%;">
          <div><span class="I">I</span><span class="M">M</span><span class="A">A</span><span class="B">B</span></div>
        </div>
      </div>
      <div v-else>
        <DataTable :value="reactiveSingleLogs" aria-label="Roster Table" scrollable scrollHeight="600px" :loading="isSubmitting || logsLoading">
            <Column field="user_email" header="User" class="text-600 w-2">
            <template #body="slotProps">
                <div class="flex items-center gap-2 pt-2">
                    <div>
                        <p class="block font-bold pb-1 m-0 p-0">
                        {{ slotProps.data.user_email }}
                        </p>
                        <p class="block text-xs text-300 m-0 p-0 pb-1">{{ slotProps.data.timestamp }}</p>
                    </div>
                </div>
            </template>
            </Column>
            <Column field="type" header="Type" class="text-600 w-2">
            <template #body="slotProps">
                <div class="flex items-center gap-2 pt-2">
                <div>
                    <p class="block font-bold pb-1 m-0 p-0">
                        {{ slotProps.data.action }}
                    </p>
                    <p class="block text-xs text-300 m-0 p-0 pb-1">{{ slotProps.data.type }}</p>
                </div>
                </div>
            </template>
            </Column>
            <Column field="action" header="Section" class="text-600 w-2">
            <template #body="slotProps">
                <div class="flex items-center gap-2 pt-2">
                <div>
                    <p class="block font-bold pb-1 m-0 p-0">
                        {{ slotProps.data.table_name }}
                    </p>
                    <p class="block text-xs text-300 m-0 p-0 pb-1">ID #{{ slotProps.data.record_id }}</p>
                </div>
                </div>
            </template>
            </Column>
            <Column field="action" header="Action" class="text-600 w-1">
            <template #body="slotProps">
                <p class="block font-bold text-400 pb-1 m-0 p-0">
                  {{ slotProps.data.priority }}
                </p>
            </template>
            </Column>
            <Column field="changes" header="Changes" class="text-600 w-full">
                <template #body="slotProps">
                    <template v-if="slotProps.data.type === 'MANUAL'">
                        <div class="text-blue-500">
                            Manual Entry: {{ getManualEntry(slotProps.data.changes) }}
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'UPDATE'">
                        <div v-if="hasChanges(slotProps.data.changes)">
                            <div v-for="(value, key) in getChangedValues(slotProps.data.changes)" :key="key" class="mb-2 w-full">
                                <div class="block flex mb-1 w-full">
                                    <span class="line-through text-red-200 w-11rem mr-2">{{ key }}:</span> 
                                    <span class="line-through w-full text-red-200">{{ formatValue(key, slotProps.data.changes.old[key]) }}</span>
                                </div>
                                <div class="block flex w-full">
                                    <span class="mr-2 text-green-600 w-11rem">{{ key }}:</span>
                                    <span class="w-full text-green-600">{{ formatValue(key, value) }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="!isManualEntry(slotProps.data.changes)" class="text-gray-500 font-italic">
                            No Change
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'INSERT'">
                        <div v-for="key in getRelevantFields(slotProps.data.table_name)" :key="key" class="mb-2 w-full">
                            <div class="block flex mb-1 w-full">
                                <span class="text-500 w-11rem mr-2">{{ key }}:</span> 
                                <span class="w-full text-500">{{ formatValue(key, slotProps.data.changes[key]) }}</span>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="slotProps.data.action === 'DELETE'">
                        <div v-for="key in getRelevantFields(slotProps.data.table_name)" :key="key" class="mb-2 w-full">
                            <div class="block flex mb-1 w-full">
                                <span class="line-through text-red-500 w-11rem mr-2">{{ key }}:</span> 
                                <span class="line-through w-full text-red-500">{{ formatValue(key, slotProps.data.changes[key]) }}</span>
                            </div>
                        </div>
                    </template>
                </template>
            </Column>
        </DataTable>
        <footer class="flex overflow-hidden mt-3">
            <div class="flex-shrink-0 flex align-items-start justify-content-end mr-2 mt-1">
                <Button label="Cancel" class="w-full surface-200 hover:surface-400" @click="handleBack" aria-label="Cancel and go back" />
            </div>
            <div class="flex-grow-1 flex-shrink-1 flex mr-2">
                <div class="w-full">
                    <InputText id="manualLogValue" v-model="manualLogValue" class="w-full mb-1" :disabled="isSubmitting" aria-describedby="username-help" />
                    <small class="text-400">Record your manual log entry here.</small>
                </div>
            </div>
            <div class="flex-shrink-0 flex mr-2 w-2">
                <div class="w-full"> 
                    <Dropdown v-model="selectPriority" :options="priorityList" optionLabel="name" placeholder="Select a City" class="w-full mb-1" />
                    <small class="text-400">Select Priority.</small>
                </div>
            </div>
            <div class="flex-shrink-0 flex align-items-start justify-content-end">
                <Button icon="pi pi-send" class="w-full bg-primary hover:surface-900 px-3 mt-1" @click="sendLog" :disabled="isSubmitting || isSubmitDisabled" :loading="isSubmitting" aria-label="Cancel and go back" />
            </div>
        </footer>
      </div>
    </div>
</template>

<script setup>
import { onMounted, watch, defineProps, defineEmits, ref, computed } from 'vue';
import { useLogsSingle } from "@/composables/api/useLogsSingle";
import { useToast } from 'primevue/usetoast';
import { useUtils } from '@/composables/useUtils';
import { useLogsCreate } from "@/composables/api/useLogsCreate";
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

const props = defineProps({
  selectedIds: {
    type: Number,
    required: true
  },
  logType: {
    type: String,
    required: true,
    default: 'attendance'
  }
});

const emit = defineEmits(['handleBack']);

const { logs, logsLoading, fetchLogsSingle } = useLogsSingle();
const { isSubmitting, submitCreateLog } = useLogsCreate();
const { dateFromIsoString } = useUtils();

const reactiveSingleLogs = ref([]);
const toast = useToast();
const manualLogValue = ref('');
const priorityList = [   
  { name: 'Low', code: 'LOW' },   
  { name: 'Medium', code: 'MEDIUM' },   
  { name: 'High', code: 'HIGH' },
];
const defaultPriority = priorityList.find(priority => priority.code === 'MEDIUM');
const selectPriority = ref(defaultPriority);

const sendLog = async () => {
  if (!manualLogValue.value.trim()) {
    toast.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter a log message', life: 3000 });
    return;
  }

  const logData = { 
    table_name: props.logType,
    action: 'UPDATE',
    record_id: props.selectedIds,
    user_email: reactiveSingleLogs.value[0]?.user_email || 'system@imab.cc',
    employee_id: reactiveSingleLogs.value[0]?.employee_id,
    type: 'MANUAL',
    priority: selectPriority.value.code,
    changes: JSON.stringify({ manual_entry: manualLogValue.value })
  };

  const result = await submitCreateLog(logData);

  if (result.success) {
    toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000, group: 'br' });
    manualLogValue.value = '';
    await loadRosterData(); // Refresh the logs
  } else {
    toast.add({ severity: result.severity || 'error', summary: 'Error', detail: result.message, life: 5000, group: 'br' });
  }
};

const isSubmitDisabled = computed(() => {
  return isSubmitting.value || !manualLogValue.value.trim();
});

const isManualEntry = (changes) => {
  return changes && changes.manual_entry !== undefined;
};

const getManualEntry = (changes) => {
  if (typeof changes === 'string') {
    try {
      changes = JSON.parse(changes);
    } catch (e) {
      console.error('Error parsing changes JSON:', e);
      return 'Error: Invalid manual entry format';
    }
  }
  return changes.manual_entry || 'No manual entry text';
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
};

const formatValue = (key, value) => {
  const dateFields = ['attendance_in_out', 'roster_start', 'roster_end'];
  if (dateFields.includes(key)) {
    return formatDate(value);
  }
  return value;
};

const hasChanges = (changes) => {
  if (isManualEntry(changes)) return true;
  return changes && changes.new && changes.old && 
         Object.keys(changes.new).some(key => changes.new[key] !== changes.old[key]);
};

const getChangedValues = (changes) => {
  if (isManualEntry(changes)) {
    return { manual_entry: changes.manual_entry };
  }
  return changes && changes.new && changes.old
    ? Object.keys(changes.new).reduce((acc, key) => {
        if (changes.new[key] !== changes.old[key]) {
          acc[key] = changes.new[key];
        }
        return acc;
      }, {})
    : {};
};
const getRelevantFields = (tableName) => {
  if (tableName === 'attendance') {
    return ['attendance_in_out', 'attendance_status'];
  } else if (tableName === 'roster') {
    return ['roster_start', 'roster_end'];
  }
  return [];
};


const loadRosterData = async () => {
  try {
    await fetchLogsSingle(props.logType, props.selectedIds, 'ALL');
    reactiveSingleLogs.value = logs.value.map(log => {
      const log_timestmap = dateFromIsoString(log.timestamp);
      return {
        ...log,
        timestamp: log_timestmap
      };
    });
    
  } catch (error) {
    console.error('Error fetching roster data:', error);
    toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to load roster data', life: 12000 });
  }
};

const handleBack = async () => {
  emit('handleBack');
};

onMounted(loadRosterData);
watch(() => props.selectedIds, loadRosterData);
</script>